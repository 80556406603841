<div *ngIf="apiLoaded | async" class="google-map-wrapper">
  <google-map
    #map="googleMap"
    [zoom]="zoom"
    [center]="center"
    [options]="mapOptions"
    (boundsChanged)="updateMap($event, false)"
    (touchstart)="preventDefault($e)">
      <map-heatmap-layer
        [data]="heatmapData"
        [options]="heatmapOptions">
      </map-heatmap-layer>
      <map-marker
        #markerElem
        *ngFor="let marker of markers"
        [position]="marker.position"
        [label]="marker.label"
        [title]="marker.title"
        [icon]="marker.icon"
        [type]="marker.type"
        (mapClick)="markerClickEvent(marker.position, marker.zoom, marker.type, marker.reference)"
        [options]="marker.options">
      </map-marker>
  </google-map>
</div>
