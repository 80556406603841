import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map, take, skipWhile } from 'rxjs/operators';

import MTLAppConfig from '../assets/mtl-app-config.json';

export interface LanguageChoice {
  value: string,
  display: string,
}

let paypalDonationButtonChoices = {
  'en': {
    'url': 'https://www.paypal.com/donate/?hosted_button_id=W37E7AGWJGGLC',
    'imgSrc': '/assets/PayPal_donation_en.svg',
    'imgAlt': 'Donate via PayPal'
  },
  'de': {
    'url': 'https://www.paypal.com/donate/?hosted_button_id=9VNAESYR43WZJ',
    'imgSrc': '/assets/PayPal_donation_de.svg',
    'imgAlt': 'Spenden mit PayPal'
  }
};

let languageChangeNotification = {
  'en': 'To render the map in english the page has to be reloaded manually.',
  'de': 'Um die Karte auf Deutsch darzustellen muss die Seite manuell neu geladen werden.',
}

let advancedSearchFields = ['res_fd_from', 'res_fd_to', 'res_quarter', 'res_district', 'res_state', 'res_country', 'birth_fd_from', 'birth_fd_to', 'birth_place', 'death_fd_from', 'death_fd_to', 'death_place', 'emigration_fd_from', 'emigration_fd_to', 'emigration_place', 'emigration_place_from', 'expulsion_fd_from', 'expulsion_fd_to', 'expulsion_place', 'expulsion_place_from', 'imprisonment_fd_from', 'imprisonment_fd_to', 'imprisonment_place', 'deportation_fd_from', 'deportation_fd_to', 'deportation_place', 'deportation_place_from'];

@Injectable()
// BASE_URL = "https://unidosmexico85.firebaseio.com";


export class ApiService {
  // http options used for making API calls
  private httpOptions: any;
  // public searchData: Observable<any[]>;
  // public geoPositionData: Observable<any[]>;

  zoomlevel = 6;
  tl_lat = 66;
  tl_lon = -180;
  br_lat = -66;
  br_lon = 180;

  public languageChoices_ = [];
  public languageChoices = new BehaviorSubject<any>([]);

  public languageCode_ = 'en';
  public languageCode = new BehaviorSubject<string>('en');
  public uITextElements = new BehaviorSubject<any>({});
  public uITextElements_: any;

  public searchParametersChanged = new BehaviorSubject<boolean>(false);
  public geoPositionData = new BehaviorSubject<any>({});
  public personReference = '';

  private searchByQuery_ = <any>[];
  public searchByQuery = new BehaviorSubject<any>({});
  private searchByQueryNextPage_ = null;
  public searchByQueryNextPage = new BehaviorSubject<string>(null);
  public searchByQueryResultCount = new BehaviorSubject<number>(0);
  public searchInProgress = new BehaviorSubject<boolean>(false);

  private searchByAddress_ = <any>[];
  public searchByAddress = new BehaviorSubject<any>({});  
  private searchByAddressNextPage_ = null;
  public searchByAddressNextPage = new BehaviorSubject<string>(null);
  public searchByAddressResultCount = new BehaviorSubject<number>(0);

  public supportUsText_ = "";
  public supportUsText = new BehaviorSubject<string>("");


  public cooperationpartner = "";
  public cooperationPartnerData_ = {};
  public cooperationPartnerData = new BehaviorSubject<any>({});
  public embeddedView = new BehaviorSubject<boolean>(false);
  public mapUpdates_ = true;
  public mapUpdates = new BehaviorSubject<boolean>(true);
  
  public slug = '';
  public page = new BehaviorSubject<any>({});
  public currentPage = new BehaviorSubject<string>('');
  public pageDataLoadingStatus = new BehaviorSubject<number>(0);
  public menuItems_;
  public menuItems = new BehaviorSubject<any>([]);
  public personData = new BehaviorSubject<any>({});

  public lastViewedBiographies_ = [];
  public lastViewedBiographies = new BehaviorSubject<any>([]);

  public personDataLoadingStatus = new BehaviorSubject<number>(0);
  public googleMapsGDPRConsent = new BehaviorSubject<boolean>(false);
  public paypalDonationButton = new BehaviorSubject<any>(paypalDonationButtonChoices.en);

  public activeSearchParametersFromAdvancedSearch = new BehaviorSubject<boolean>(false);

  public consentGiven_ = false;
  public consentGiven = new BehaviorSubject<boolean>(false);

  public permanentConsentGiven_ = localStorage.getItem('MTL_rememberConsent') == 'true';
  public permanentConsentGiven = new BehaviorSubject<boolean>(this.permanentConsentGiven_);

  pageSize = 100;
  searchAtCoordinatesCoordinates: any;

  searchParameters = {};

  updateTimer: any;
  updateInterval = 800;

  namingQualifiers = {};
  basisOfPersecutionQualifiers = {};
  dateFlags = {};
  biologicalGender = {};

  public setConsentGiven(permanent=false): void {
    this.consentGiven.next(true);
    if(permanent) {
      localStorage.setItem('MTL_rememberConsent', 'true');
      localStorage.setItem('MTL_languageCode', this.languageCode_);
    }
  }

  public togglePermanentConsent(): void {
    this.permanentConsentGiven_ = !this.permanentConsentGiven_;
    if(!this.permanentConsentGiven_) {
      localStorage.removeItem('MTL_rememberConsent');
      localStorage.removeItem('MTL_languageCode');
    }
    else if( this.consentGiven_ == true ){
      localStorage.setItem('MTL_rememberConsent', 'true');
      localStorage.setItem('MTL_languageCode', this.languageCode_);
    }
    this.permanentConsentGiven.next(this.permanentConsentGiven_);
  }

  // public pageIsCurrent(slug): boolean {
  //     return this.slug == slug;
  //   }

  // public personIsCurrent(reference): boolean {
  //   return this.personReference == reference;
  // }

  private fitToBounds = new BehaviorSubject<any>({});

  private pad(num:number, size:number): string {
      let s = num+"";
      while (s.length < size) s = "0" + s;
      return s;
  }

  public getFitToBounds(): Observable<any> {
    return this.fitToBounds.asObservable();
  }

  public toggleMapUpdates(): void {
    this.mapUpdates_ = !this.mapUpdates_;
    this.mapUpdates.next(this.mapUpdates_);
  }

  public setSearchByAddressResultCount(num): void {
    this.searchByAddressResultCount.next(num);
  }

  public replaceNamingQualifierPlaceholder(name): string {
    for(const [qualifier, qualifierTranslation] of Object.entries(this.namingQualifiers)) {
      name = name.replace(qualifier, qualifierTranslation);
    }
    return name;
  }

  public replaceBasisOfPersecutionPlaceholder(name): string {
    for(const [qualifier, translation] of Object.entries(this.basisOfPersecutionQualifiers)) {
      name = name.replace(qualifier, translation);
    }
    return name;
  }

  public replaceDateFlagPlaceholder(name): string {
    for(const [qualifier, translation] of Object.entries(this.dateFlags)) {
      name = name.replace(qualifier, translation);
    }
    return name;
  }

  public replaceGenderPlaceholder(name): string {
    for(const [qualifier, translation] of Object.entries(this.biologicalGender)) {
      name = name.replace(qualifier, translation);
    }
    return name;
  }

  public setFitToBounds( value ): void {
    this.fitToBounds.next(value);
  }


  public getMenuItems(): void {
    var url = MTLAppConfig.server.address + 'page/?format=json&language=' + this.languageCode_;
    this.http.get(url, this.httpOptions).subscribe(
      data => {
        // console.log(data)
        this.menuItems_ = data;
        this.menuItems.next(this.menuItems_);
    },
    error => console.error(error),
    () => {
      }
    );
  }

  public setPage( value: string ): void {
    if (value != "" && value != null) {
      this.slug = value.split('#')[0];
      var url = MTLAppConfig.server.address + 'page/' + this.slug + '/?format=json&language=' + this.languageCode_;
      this.http.get(url, this.httpOptions).subscribe(
        data => {
          if (data.hasOwnProperty('message')){
            this.slug = "";
            this.page.next({});
            this.currentPage.next("");
            this.pageDataLoadingStatus.next(2)
          }
          else
          {
            // console.log(data)
            this.page.next(data);
            this.currentPage.next(this.slug);
            this.pageDataLoadingStatus.next(1);
          }
      },
      error => {
        console.error(error);
        this.pageDataLoadingStatus.next(2);
      });
    }
    else
    {
      this.slug = "";
      this.page.next({});
      this.currentPage.next("");
      this.pageDataLoadingStatus.next(0);
    }
  }

  public setLanguageCode(languageCode): string {
    if (languageCode != this.languageCode_)
    {
      if (languageCode == null){
        this.languageCode_ = "en";
      }
      else
      {
          this.languageCode_ = languageCode;
      }
      this.languageCode.next(this.languageCode_);
      if( this.consentGiven_ == true ){
        localStorage.setItem('MTL_languageCode', this.languageCode_);
      }
      // if(throughUI) {
      //   window.alert(languageChangeNotification[languageCode]);
      // }
      this.fetchUITextElements();
      // if (this.currentDisplay)
      this.fetchPersonData();
      this.getMenuItems();
      this.setPage(this.slug);
      this.paypalDonationButton.next(paypalDonationButtonChoices[this.languageCode_]);
    }
    return this.languageCode_;
  }

  // search_parameters_by_type = {
  //   string: [
  //     {field: firstname, apiField: firstname},
  //     {field: lastname, apiField: lastname},
  //                                 'res_street', 'res_streetnr', 'res_community', 'res_quarter', 'res_district', 'res_state', 'res_country',
  //                                 'birth_place',
  //                                 'death_place',
  //                                 'emigration_place',
  //                                 'expulsion_place',
  //                                 'imprisonment_place',
  //                                 'deportation_place'
  //                                 ],
  //                             'date': [
  //                                 'res_fd_from', 'res_fd_to',
  //                                 'birth_fd_from', 'birth_fd_to',
  //                                 'death_fd_from', 'death_fd_to',
  //                                 'emigration_fd_from', 'emigration_fd_to',
  //                                 'expulsion_fd_from', 'expulsion_fd_to',
  //                                 'imprisonment_fd_from', 'imprisonment_fd_to',
  //                                 'deportation_fd_from', 'deportation_fd_to',
  //
  // }


  getDateToString(year, month, day, from): string {
    if ( (year == null || year == "") && (month == null || month == "") && (day == null || day == "") ){
      return null;
    }
    if ( year == "" ) {
      return ""
    }
    if ( month == "" )
    {
      month = 1;
    }
    if ( day == "" ) {
      day = 1;
    }
    return year + '-' + this.pad(month, 2) + '-' + this.pad(day, 2) + 'T' + (from ? '00:00' : '23:59');
  }


  public setSearchParameters(values, triggerRefresh=true){
    // console.log(values)
    var searchParameters = {
      'restrict_to_map_bounds': values.filterByVisibleMap,
      'filter_results': values.filterResults,
      'firstname': values.firstname,
      'lastname': values.lastname,
      'res_fd_from': this.getDateToString(values.residence.residence_date_from.residence_date_from_year, values.residence.residence_date_from.residence_date_from_month, values.residence.residence_date_from.residence_date_from_day, true),
      'res_fd_to': this.getDateToString(values.residence.residence_date_to.residence_date_to_year, values.residence.residence_date_to.residence_date_to_month, values.residence.residence_date_to.residence_date_to_day, false),
      'res_street': values.residence.street_streetnumber.street,
      'res_streetnr': values.residence.street_streetnumber.streetnumber,
      'res_community': values.residence.community,
      'res_quarter': values.residence.quarter,
      'res_district': values.residence.district,
      'res_state': values.residence.state,
      'res_country': values.residence.country,
      'birth_fd_from': this.getDateToString(values.birth_death.birth.birth_date_from.birth_date_from_year, values.birth_death.birth.birth_date_from.birth_date_from_month, values.birth_death.birth.birth_date_from.birth_date_from_day, true),
      'birth_fd_to': this.getDateToString(values.birth_death.birth.birth_date_to.birth_date_to_year, values.birth_death.birth.birth_date_to.birth_date_to_month, values.birth_death.birth.birth_date_to.birth_date_to_day, false),
      'birth_place': values.birth_death.birth.birth_place,
      'death_fd_from': this.getDateToString(values.birth_death.death.death_date_from.death_date_from_year, values.birth_death.death.death_date_from.death_date_from_month, values.birth_death.death.death_date_from.death_date_from_day, true),
      'death_fd_to': this.getDateToString(values.birth_death.death.death_date_to.death_date_to_year, values.birth_death.death.death_date_to.death_date_to_month, values.birth_death.death.death_date_to.death_date_to_day, false),
      'death_place': values.birth_death.death.death_place,
      'emigration_fd_from': this.getDateToString(values.emigration.emigration_date_from.emigration_date_from_year, values.emigration.emigration_date_from.emigration_date_from_month, values.emigration.emigration_date_from.emigration_date_from_day, true),
      'emigration_fd_to': this.getDateToString(values.emigration.emigration_date_to.emigration_date_to_year, values.emigration.emigration_date_to.emigration_date_to_month, values.emigration.emigration_date_to.emigration_date_to_day, false),
      'emigration_place': values.emigration.emigration_place,
      'emigration_place_from': values.emigration.emigration_place_from,
      'expulsion_fd_from': this.getDateToString(values.expulsion.expulsion_date_from.expulsion_date_from_year, values.expulsion.expulsion_date_from.expulsion_date_from_month, values.expulsion.expulsion_date_from.expulsion_date_from_day, true),
      'expulsion_fd_to': this.getDateToString(values.expulsion.expulsion_date_to.expulsion_date_to_year, values.expulsion.expulsion_date_to.expulsion_date_to_month, values.expulsion.expulsion_date_to.expulsion_date_to_day, false),
      'expulsion_place': values.expulsion.expulsion_place,
      'expulsion_place_from': values.expulsion.expulsion_place_from,
      'imprisonment_fd_from': this.getDateToString(values.imprisonment.imprisonment_date_from.imprisonment_date_from_year, values.imprisonment.imprisonment_date_from.imprisonment_date_from_month, values.imprisonment.imprisonment_date_from.imprisonment_date_from_day, true),
      'imprisonment_fd_to': this.getDateToString(values.imprisonment.imprisonment_date_to.imprisonment_date_to_year, values.imprisonment.imprisonment_date_to.imprisonment_date_to_month, values.imprisonment.imprisonment_date_to.imprisonment_date_to_day, false),
      'imprisonment_place': values.imprisonment.imprisonment_place,
      'deportation_fd_from': this.getDateToString(values.deportation.deportation_date_from.deportation_date_from_year, values.deportation.deportation_date_from.deportation_date_from_month, values.deportation.deportation_date_from.deportation_date_from_day, true),
      'deportation_fd_to': this.getDateToString(values.deportation.deportation_date_to.deportation_date_to_year, values.deportation.deportation_date_to.deportation_date_to_month, values.deportation.deportation_date_to.deportation_date_to_day, false),
      'deportation_place': values.deportation.deportation_place,
      'deportation_place_from': values.deportation.deportation_place_from,
    };
    let activeFieldsFromAdvanced = false;
    for(let fieldname of advancedSearchFields) {
      let value = searchParameters[fieldname];
      // console.log(fieldname, value);
      if(value != undefined && value != null && value != '') {
        activeFieldsFromAdvanced = true;
        break;
      }
    }
    this.activeSearchParametersFromAdvancedSearch.next(activeFieldsFromAdvanced);
    this.searchParameters = searchParameters;
    if (triggerRefresh) {
      this.triggerFetchData();
    }
  }

  public clearSearchParameters(){
    this.searchParameters = {};
    this.triggerFetchData();
    this.searchByQuery_ = [];
    this.searchByQuery.next([]);
    this.searchByQueryNextPage_ = null;
    this.searchByQueryResultCount.next(0);
  }

  public getLanguageChoices() {
    var url = MTLAppConfig.server.address + 'configuration/supported_languages/?format=json';
    return this.http.get(url, this.httpOptions);
  }

  public getUITextElements() {
    var url = MTLAppConfig.server.address + 'configuration/?format=json&language_code=' + this.languageCode_;
    return this.http.get(url, this.httpOptions);
  }

  public fetchUITextElements() {
    this.getUITextElements().subscribe(
      data => {
          if (this.uITextElements_ != data){
            this.uITextElements.next(data);
            this.uITextElements_ = data;
          }
      }
    );
  }

  public getsearchByQueryNextPage() {
    var url = this.searchByQueryNextPage_;
    return this.http.get(url, this.httpOptions);
  }

  constructor(private http: HttpClient) {

    this.getLanguageChoices().subscribe(
      data => {
          this.languageChoices_ = []
          for(let choice in data) {
            this.languageChoices_.push(choice['value']);
          }
          this.languageChoices.next(data);
      }
    );
    
    let languageCodeSet = false;
    if(localStorage.getItem('MTL_rememberConsent') == 'true'){
      this.consentGiven_ = true;
      this.consentGiven.next(true);
      var languageCodeFromLocalStorage = localStorage.getItem('MTL_languageCode');
      if ( languageCodeFromLocalStorage != undefined && languageCodeFromLocalStorage != '' && this.languageCode_ != languageCodeFromLocalStorage) {
        this.languageCode_ = languageCodeFromLocalStorage;
        this.languageCode.next(this.languageCode_);
        languageCodeSet = true;
      }
    }

    if ( !languageCodeSet ) {
      let browserLanguageCode = window.navigator.language.split('-')[0].toLowerCase();
      if (this.languageCode_ != browserLanguageCode && this.languageChoices_.includes(browserLanguageCode)){
        this.languageCode_ = browserLanguageCode;
        this.languageCode.next(this.languageCode_);
      }
    }

    if( this.consentGiven_ ) {
      this.searchParametersChanged.next(false);
      this.triggerFetchData();
    }

    this.httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
    this.fetchUITextElements();
      // if (this.currentDisplay)
    this.fetchPersonData();
    this.getMenuItems();
    this.setPage(this.slug);
    this.paypalDonationButton.next(paypalDonationButtonChoices[this.languageCode_]);
    // this.uITextElements.subscribe(
    //   data => {
    //     this.namingQualifiers = data.namingQualifiers;
    //     this.basisOfPersecutionQualifiers = data.basisOfPersecution;
    //     this.dateFlags = data.dateFlags;
    //     this.biologicalGender = data.biologicalGender;
    //   }
    // )
    // LOCATIONS_URL = "/locations.json";
  }




  public setCooperationPartner(cooperationpartner): void {
    var url = MTLAppConfig.server.address + 'cooperationpartner/' + cooperationpartner + '/?format=json';
    this.http.get(url, this.httpOptions).subscribe(
      data => {
        this.cooperationPartnerData_ = data;
        // console.log('coop: ' + data)
        // console.log(data)
        this.cooperationPartnerData.next(this.cooperationPartnerData_);
        this.embeddedView.next(this.cooperationPartnerData_['embedded_view']);
    },
    error => console.error(error),
    () => {
      }
    );
    
    // .subscribe(
    //     data => {
    //       try {
            // console.log(data)
    //           // this.cooperationpartner_data.name = data['name'];
    //           this.cooperationpartner_data.center = data['center'];
    //           this.cooperationpartner_data.zoom = data['zoom'];
    //       }
    //       catch(e) {
    //         console.error(e);
    //       }
    //     },
    //     error => console.error(error),
    //     () => {
    //         this.cooperationpartner_reference = cooperationpartner;
    //       }
    //   );
  }

  public setMapBounds(zoomlevel=6, tl_lat = 66, tl_lon=-180, br_lat=-66, br_lon=180): void {
    this.zoomlevel = zoomlevel;
    this.tl_lat = tl_lat;
    this.tl_lon = tl_lon;
    this.br_lat = br_lat;
    this.br_lon = br_lon;
    this.triggerFetchData();
  }

  handleListResults(data, refreshGeoData = true): void {
    if ( refreshGeoData ) {
      if ( data.hasOwnProperty('facets') == true ) {
        this.geoPositionData.next(data['facets']);
      }
    }
    if ( data.hasOwnProperty('results') == true ) {
      this.searchByQuery.next(data['results']);
      this.searchByQuery_ = data['results'];
      this.searchByQueryResultCount.next(data['results'])
    }
    if ( data.hasOwnProperty('next') == true ) {
      this.searchByQueryNextPage_ = data['next'];
      this.searchByQueryNextPage.next(data['next']);
    }
    else
    {
      this.searchByQueryNextPage.next(null);
    }

    if ( data.hasOwnProperty('count') == true ) {
      this.searchByQueryResultCount.next(data['count']);
    }
    else
    {
      this.searchByQueryResultCount.next(0);
    }
  }

  public setSearchInProgress(value): void {
    this.searchInProgress.next(value);
  }

  public searchAtCoordinates(center, filter=false) {
    // console.log(center.toJSON())
    this.setSearchInProgress(true);

    if (filter == false) {
      this.searchAtCoordinatesCoordinates = center.toJSON();
    }
    var url = MTLAppConfig.server.address + 'search/search_by_place/?format=json&page_size=' + this.pageSize + '&res_lat=' + this.searchAtCoordinatesCoordinates.lat + '&res_lon=' + this.searchAtCoordinatesCoordinates.lng;
    url += this.combineSearchParameters();
    this.http.get(url, this.httpOptions).subscribe(
      data => {
        this.searchByAddress_ = data['results'];
        this.searchByAddress.next(data['results']);
        if ( data.hasOwnProperty('count') == true ) {
          this.searchByAddressResultCount.next(data['count']);
        }
        else
        {
          this.searchByAddressResultCount.next(0);
        }
      });
    this.setSearchInProgress(false);
  }

  public getGeoPositionData(): Observable<any> {
    return this.geoPositionData.asObservable();
  }

  public getSearchByQuery(): Observable<any> {
    return this.searchByQuery.asObservable();
  }

  public setPersonReference(reference): void {
    this.personReference = reference;
    this.fetchPersonData();
  }

  fetchPersonData(): void {
    if (this.personReference != "") {
      this.personDataLoadingStatus.next(0);
      var url = MTLAppConfig.server.address + 'search/' + this.personReference + '/person_details/?language=' + this.languageCode_;
      this.http.get(url, this.httpOptions).subscribe(
        data => {
          // console.log(data);
           this.personData.next(data);

           let lastViewedLength = this.lastViewedBiographies_.length;
           for(let i=0; i < lastViewedLength; i++) {
             if( this.lastViewedBiographies_[i]?.reference == this.personReference ){
               this.lastViewedBiographies_.splice(i,1);
             }
             else if( i > 4) {
              this.lastViewedBiographies_.splice(i,1);
             }
           }
           this.lastViewedBiographies_.unshift({'reference': this.personReference, 'fullname': data['fullname_list_display'], 'fullname_list_order': data['fullname_list_order'], 'residence_on_map': data['residence_on_map']});
           this.lastViewedBiographies.next(this.lastViewedBiographies_);
           this.personDataLoadingStatus.next(1);
        },
        error => {
          this.personDataLoadingStatus.next(2);
        }
        );
    }
    this.personData.next({});
    
  }

  triggerFetchData(): void {
    this.setSearchInProgress(true);
    clearTimeout(this.updateTimer);
    this.updateTimer = setTimeout(() => { this.fetchSearchDataFromServer() }, this.updateInterval);
  }

  combineSearchParameters(): string {
    var result = "";
    for (let key in this.searchParameters) {
        let value = this.searchParameters[key];
        if ( key == 'restrict_to_map_bounds' ) {
          result += "&" + key + "=" + value;
        }
        else
        {
          if ( value != "" && value != null ) {
            result += "&" + key + "=" + value;
          }
        }

    }
    return result;
  }

  searchByQueryFetchNextPage(): void {
    if (this.searchByQueryNextPage_ != null) {
      this.http.get(this.searchByQueryNextPage_, this.httpOptions).subscribe(
        data => {
          if ( data.hasOwnProperty('results') == true ) {
            this.searchByQuery_ = this.searchByQuery_.concat(data['results']);
            this.searchByQuery.next(this.searchByQuery_);
          }
          if ( data.hasOwnProperty('next') == true ) {
            this.searchByQueryNextPage_ = data['next'];
            this.searchByQueryNextPage.next(data['next']);
          }
          else
          {
            this.searchByQueryNextPage_ = null;
            this.searchByQueryNextPage.next(null);
          }
        }
      )
    }
  }

  searchByAddressFetchNextPage(): void {
    if (this.searchByAddressNextPage_ != null) {
      this.http.get(this.searchByAddressNextPage_, this.httpOptions).subscribe(
        data => {
          if ( data.hasOwnProperty('results') == true ) {
            this.searchByAddress_ = this.searchByAddress_.concat(data['results']);
            this.searchByAddress.next(this.searchByAddress_);
          }
          if ( data.hasOwnProperty('next') == true ) {
            this.searchByAddressNextPage_ = data['next'];
            this.searchByAddressNextPage.next(data['next']);
          }
          else
          {
            this.searchByAddressNextPage_ = null;
            this.searchByAddressNextPage.next(null);
          }
        }
      )
    }
  }


  public fetchSearchDataFromServer(): void {

      this.setSearchInProgress(true);
      var url = MTLAppConfig.server.address + 'search/?format=json&page_size=' + this.pageSize + '&zoom=' + this.zoomlevel + '&tl_lat=' + this.tl_lat + '&tl_lon=' + this.tl_lon + '&br_lat=' + this.br_lat + '&br_lon=' + this.br_lon;

      let combinedSearchParameters = this.combineSearchParameters();
      url += combinedSearchParameters;
      // this.geoPositionData = of({});

      if (this.mapUpdates_) {
        // console.log(url)
        this.http.get(url, this.httpOptions).subscribe(
          data => {
            this.handleListResults(data);
            // console.log(this.geoPositionData)
          }
        );
        this.searchParametersChanged.next(false);

      }
      // return response;
  }
  //
  // public getMapData(zoomlevel=6, tl_lat = 66, tl_lon=-180, br_lat=-66, br_lon=180): Observable<any> {
      // console.log("Calling this");
  //
  // }
}
