import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../api.service';
import { ApplicationStateService } from '../application-state.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})
export class MenuComponent implements OnInit {
  @Input() personFullscreen = false;
  public languageCode = 'en';
  public menuOpen = false;
  public uiTranslations;
  public currentDisplay;
  
  public languageChoices = [];
  public menuItems = [];
  public lastViewedBiographies = [];
  public slug = "";
  public currentPage = "";
  public rightOverlayFullscreen = false;

  public getValueToDisplay(data): string {
    if ( data != undefined ) {
      if ( data.hasOwnProperty(this.languageCode) == true ) {
        return data[this.languageCode];
      }
      if ( data.hasOwnProperty("default") == true ) {
        return data.default;
      }
    }
    return "";
  }

  public compareFullnames( a, b ): number {
    let languageCode = "en";
    if ( a.fullname_list_order[languageCode] < b.fullname_list_order[languageCode] ){
      return -1;
    }
    if ( a.fullname_list_order[languageCode] > b.fullname_list_order[languageCode] ){
      return 1;
    }
    return 0;
  }

  constructor(public applicationState: ApplicationStateService, public apiService: ApiService,) { 
    this.apiService.lastViewedBiographies.subscribe( 
      data => {
        this.lastViewedBiographies = data;
      }
    );

    this.apiService.currentPage.subscribe( 
      data => {
        this.currentPage = data;
      }
    );

    this.applicationState.currentDisplay.subscribe(
      value => {
        // console.log('applicationState.currentDisplay', value);
        this.slug = this.apiService.slug;
        this.currentDisplay = value;
      }
    );

    this.applicationState.menuOpen.subscribe(
      value => {
        this.menuOpen = value;
      }
    );

    this.applicationState.rightOverlayFullscreen.subscribe(
      value => {
        this.rightOverlayFullscreen = value;
      }
    );

    this.apiService.uITextElements.subscribe(
      data => {
        if ( data.hasOwnProperty('general') == true ) {
          this.uiTranslations = data.general;
        }
      }
    );

    this.apiService.languageChoices.subscribe(
      value => {
        this.languageChoices = value;
     });

    this.apiService.languageCode.subscribe(
      data => {
        this.languageCode = data;
        // console.log("receiving language choice "+ data)
      }
    );

    this.apiService.menuItems.subscribe(
      data => {
        if ( data.length > 0 ) {
          this.menuItems = data;
        }
      }
    );
  }

  ngOnInit(): void {
  }

}
