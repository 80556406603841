import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { ApplicationStateService } from '../application-state.service';
import { Clipboard } from '@angular/cdk/clipboard';


@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.less'],
  host: { class: 'app-person' }
})
export class PersonComponent implements OnInit {
  dataLoadingStatus = 0;
  person: any;

  // person: any;
  private sub: any;
  public uiTranslations: any;
  public languageCode = 'en';
  embeddedView = false;
  public linkUrl = '';
  public copiedSucessfully = false;
  currentDisplay = 'landing_page';
  personDetails = {
      personBirth: null,
    };


  constructor(private apiService: ApiService, public applicationState: ApplicationStateService, private clipboard: Clipboard) {
    this.apiService.personDataLoadingStatus.subscribe(
      value => {
        this.dataLoadingStatus = value;
      }
    )
    this.applicationState.currentDisplay.subscribe(
      value => {
        this.currentDisplay = value;
      }
  );
    this.apiService.uITextElements.subscribe(
      data => {
        this.uiTranslations = data.person;
      }
    );
    this.apiService.languageCode.subscribe(
      data => {
        this.languageCode = data;
      }
    );
    this.apiService.embeddedView.subscribe(
      data => {
        this.embeddedView = data;
      }
    );
    this.apiService.personData.subscribe(
      data => {
         this.person = data;
      },
    );
    
    
  }

  public showLink(): void {
    this.linkUrl = 'https://mappingthelives.org/bio/' + this.person?.reference + '?language=' + this.languageCode;
  }

  public copyLink(): void {
    try {
      this.clipboard.copy(this.linkUrl);
      window.alert(this.uiTranslations?.copiedSucessfully);
      // this.copiedSucessfully = true;
      // setTimeout(() => {
      //     this.copiedSucessfully = false;
      //   },2000)
    }
    catch(e) {
      // this.copiedSucessfully = false;
    }
  }


  public replaceNamingQualifierPlaceholder(name: string): string {
    return this.apiService.replaceNamingQualifierPlaceholder(name);
  }

  public replaceBasisOfPersecutionPlaceholder(name: string): string {
    return this.apiService.replaceBasisOfPersecutionPlaceholder(name);
  }

  public replaceDateFlagPlaceholder(name: string): string {
    return this.apiService.replaceDateFlagPlaceholder(name);
  }

  public replaceGenderPlaceholder(name: string): string {
    return this.apiService.replaceGenderPlaceholder(name);
  }

  public scrollToSource(source) {
    source = source.replace('.', '\\.');
    document.querySelector('#source-'+source)?.scrollIntoView({ block: 'start' });
  }

  ngOnInit(): void {
  //   this.applicationState.setBlurMap(true);
    
  //
   }

}


