
<div class="search-wrapper" [ngClass]="{hidden: (currentDisplay=='advanced_search')}">
  <div class="form-header">
    <mat-tab-group [selectedIndex]="selectedIndex" [ngClass]="{'mobile-view': applicationState.mobileView, 'minimized': currentDisplay=='map'}">
      <mat-tab>
        <ng-template mat-tab-label mat-stretch-tabs>
          <img src="/assets/search.svg" class="search-app-search-icon">
          <span class="form-header-title">
            {{searchUITranslations?.search | titlecase }}
          </span>
        </ng-template>
        <form [formGroup]="searchForm" class="search-form">
          <input type="text"  class="form-control" id="firstname" placeholder="{{ searchUITranslations?.firstname }}" formControlName="firstname" name="firstname" [value]="searchForm.value.firstname">
          <input type="text"  class="form-control" id="lastname" placeholder="{{ searchUITranslations?.lastname }}" formControlName="lastname" name="lastname" [value]="searchForm.value.lastname">
          <div formGroupName="residence">
            <div formGroupName="street_streetnumber" class="street_streetnumber">
              <input type="text"  class="form-control" id="street" placeholder="{{searchUITranslations?.street}}" formControlName="street" name="street" [value]="searchForm.value.residence.street_streetnumber.street">
              <input type="text"  class="form-control" id="streetnumber" placeholder="{{searchUITranslations?.streetnumber}}" formControlName="streetnumber" name="streetnumber" [value]="searchForm.value.residence.street_streetnumber.streetnumber">
            </div>
            <input type="text"  class="form-control" id="community" placeholder="{{searchUITranslations?.community}}" formControlName="community" name="community" [value]="searchForm.value.residence.community">
          </div>
          <button [ngClass]="{'search-in-progress': searchInProgress}" (click)="onSubmit();">
            <span>{{ searchUITranslations?.search }}</span>
          </button>
        </form>
        <span class="search-text">
          {{searchUITranslations?.moreDetails}} <u class="link_to_advanced" (click)="applicationState.setCurrentDisplay('advanced_search')">{{ searchUITranslations?.advancedSearch }}</u>
        </span>
        <span class="search-text">
          {{searchUITranslations?.searchInstructions}}
        </span>
      </mat-tab>
      <mat-tab *ngIf="consentGiven">
        <ng-template mat-tab-label>
          <img src="/assets/location_search.svg" class="search-app-search-icon">
          <span class="form-header-title">
            {{searchUITranslations?.zoomTo | titlecase }}
          </span>

        </ng-template>
        <div class="location-search-wrapper">
          <span class="search-text">
            {{searchUITranslations?.locationInstructions}}
          </span>
          <span *ngIf="locationNotFound" class="location-not-found-error">
            {{ searchUITranslations?.locationNotFound }}
          </span>
          <form [formGroup]="locationForm" (ngSubmit)="onLocationFormSubmit()">
            <input type="text"  class="form-control" id="search_address" placeholder="{{searchUITranslations?.address}}" formControlName="search_address" name="search_address">
            <!-- <input type="submit" value="{{searchUITranslations?.zoomTo}}"> -->
          </form>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <img src="/assets/results.svg" class="search-app-search-icon">
          <span class="form-header-title">
            {{searchUITranslations?.results | titlecase }}
          </span>
        </ng-template>
        <!-- This will be shown if a search is active and the user hasn't clicked on a specific marker on the map to view the specific residents -->
        <div class="search-result-wrapper search-result-wrapper-search-by-query" *ngIf="searchByQuery && !searchByAddress">
          <div class="search-results-header">
            <form [formGroup]="filterResultsForm" (ngSubmit)="onSubmitFilterResults()">
              <div class="filter-results-wrapper">
                <div class="filter-results-wrapper">
                  <input type="input"  class="form-control" id="filterResults" placeholder="{{ searchUITranslations?.filterResults| titlecase }}" (keyup)="filterResultsChanged()" formControlName="filterResults" name="filterResults">
                  <img class="filter-results-clear-icon inverted" src="/assets/close.svg" [ngClass]="{hidden: filterResultsFormClearButtonHidden}" (click)="clearFilterResults()">
                </div>
              <!-- <img class="filter-results-search-icon" src="/assets/search.svg" (click)="onSubmitFilterResults()"> -->
              </div>
              <!-- <input type="submit" value="{{searchUITranslations?.filter}}"> -->
            </form>
            <div class="search-result-header-row">
              <div (click)="applicationState.setCurrentDisplay('advanced_search')"><img class="search-results-edit-search-icon" src="/assets/edit_search.svg"></div>
              <span class="search-result-count" [ngClass]="{hidden: searchInProgress}"><img src="/assets/search_bounds_blueish.svg" *ngIf="filterByVisibleMap" class="search-result-count-restrict-to-map-icon">{{ searchByQueryResultCount }} {{searchUITranslations?.resultCount}}</span>
              <div (click)="toggleSearchFilter()"><img class="search-results-filter-icon" src="/assets/filter_active.svg" *ngIf="filterActive"><img class="search-results-filter-icon" src="/assets/filter.svg" *ngIf="!filterActive"></div>
            </div>
          </div>
          <div class="search-result-content-wrapper">
            <div class="search-results-filter-wrapper" [ngClass]="{hidden: searchFilterHidden}">
              <span class="search-result-filter-title"><img src="/assets/filter_dark.svg" class="search-result-filter-title-icon">Filter</span> 
              <mat-slide-toggle
                      class="example-margin"
                      [labelPosition]="labelPosition"
                      [checked]="filterByVisibleMap"
                      [color]="sliderColor"
                      (change)="toggleFilterByMap()"
                      [disabled]="false">
                      <div class="search-result-filter-label-with-left-icon">
                        <img src="/assets/search_bounds_dark.svg" class="search-result-filter-label-with-left-icon-icon restrict-to-map-icon">
                        <span>{{ searchUITranslations?.restrictToMap }} </span>
                      </div>
                      
              </mat-slide-toggle>
              <button (click)="setSearchFilterHidden(true)" class="search-result-filter-back-button">{{ searchUITranslations?.backToTheResults | titlecase }} </button>
            </div>
            <div class="search-results-loading-animation-wrapper" [ngClass]="{hidden: !searchInProgress}">
              <div class="search-results-loading-spinner"><div></div><div></div><div></div><div></div></div>
            </div>
            <div class="search-result-list-wrapper">
              <button (click)="clearSearch(true)" [ngClass]="{hidden: !filterResultsFormClearButtonHidden}" class="search-result-clear-search-button">{{ searchUITranslations?.clearSearch }}</button>
              <button (click)="clearFilterResults()" [ngClass]="{hidden: filterResultsFormClearButtonHidden}" class="search-result-clear-search-button">{{ searchUITranslations?.clearFilter | titlecase }}</button>
              <div *ngFor="let result of searchByQueryResults; let i = index;">
                <div *ngIf="i==0" class="search-result-list-letter">
                  {{ getValueToDisplay(result?.fullname_display_list).charAt(0).toUpperCase() }}
                </div>
                <div *ngIf="i>0 && getValueToDisplay(result?.fullname_display_list).charAt(0).toUpperCase() != getValueToDisplay(searchByQueryResults[i-1].fullname_display_list).charAt(0).toUpperCase()" class="search-result-list-letter">
                    {{ getValueToDisplay(result.fullname_display_list).charAt(0).toUpperCase() }}
                </div>
                <div class="search-result-list-item" [routerLink]="['/bio/', result.reference ]">
                  <span class="search-result-list-item-name">{{ getValueToDisplay(result?.fullname_display_list) }}</span>
                  <span class="search-result-list-item-address">{{ getValueToDisplay(result?.residence_on_map?.place_name_display_list) }}</span>
                </div>
              </div>
              <div *ngIf="searchByQueryResults.length == 0 && !searchInProgress" class="search-result-none-message-wrapper">
                <span *ngIf="filterByVisibleMap && !searchByAddress" [innerHtml]="searchUITranslations?.noResultsActiveFilter"></span>
                <div *ngIf="!(filterByVisibleMap && !searchByAddress)" class="search-result-no-results-found-wrapper">
                  <span class="search-result-no-results">{{searchUITranslations?.noResults}}</span>
                  <div [innerHtml]="searchUITranslations?.noResultsContribute"></div>
                  <a href="mailto:info@tracingthepast.org">info@tracingthepast.org</a>
                </div>
              </div>
              <button *ngIf="searchByQueryNextPage!=null" (click)="apiService.searchByQueryFetchNextPage()">{{ searchUITranslations?.loadMore | titlecase }}</button>
            </div>
          </div>
        </div>
        <!-- This will be shown if a user has clicked on a specific marker on the map to view the specific residents -->
        <div class="search-result-wrapper search-result-wrapper-search-by-address" *ngIf="searchByAddress">
          <div class="search-results-header">
            <form [formGroup]="filterResultsForm" (ngSubmit)="onSubmitFilterResultsAtAddress()">
              <div class="filter-results-wrapper">
                <div class="filter-results-wrapper">
                  <input type="input"  class="form-control" id="filterResults" placeholder="{{ searchUITranslations?.filterResults| titlecase }}" (keyup)="filterResultsChangedAtAddress()" formControlName="filterResults" name="filterResults">
                  <img class="filter-results-clear-icon inverted" src="/assets/close.svg" [ngClass]="{hidden: filterResultsFormClearButtonHidden}" (click)="clearFilterResultsAtAdress()">
                </div>
                <!-- <img class="filter-results-search-icon" src="/assets/search.svg" (click)="onSubmitFilterResultsAtAddress()"> -->

              <!-- <input type="submit" value="{{searchUITranslations?.filter}}"> -->
            
              </div>
            </form>
            <div class="search-result-header-address">
              <span class="search-result-count" [ngClass]="{hidden: searchInProgress}">{{ searchByAddressResultCount }} {{searchUITranslations?.resultCount}}</span>
            </div>
          </div>
          <div class="search-result-content-wrapper">
            <div class="search-result-list-wrapper">
            <button (click)="clearSearchAtAdress()" [ngClass]="{hidden: !(filterResultsFormClearButtonHidden && !searchByQuery)}" class="search-result-clear-search-button">{{ searchUITranslations?.clearSearch | titlecase }}</button>
            <button (click)="clearSearchAtAdress()" [ngClass]="{hidden: !(filterResultsFormClearButtonHidden && searchByQuery)}" class="search-result-clear-search-button"> < {{ searchUITranslations?.backToSearch | titlecase }}</button>
            <button (click)="clearFilterResultsAtAdress()" [ngClass]="{hidden: filterResultsFormClearButtonHidden}" class="search-result-clear-search-button">{{ searchUITranslations?.clearFilter | titlecase }}</button>
              <div *ngFor="let result of searchByAddressResults; let i = index;">
                <div *ngIf="i==0" class="search-result-list-letter">
                  {{ getValueToDisplay(result.fullname_display_list).charAt(0).toUpperCase() }}
                </div>
                <div *ngIf="i>0 && getValueToDisplay(result.fullname_display_list).charAt(0).toUpperCase() != getValueToDisplay(searchByAddressResults[i-1].fullname_display_list).charAt(0).toUpperCase()" class="search-result-list-letter">
                    {{ getValueToDisplay(result.fullname_display_list).charAt(0).toUpperCase() }}
                </div>
                <div class="search-result-list-item" [routerLink]="['/bio/', result.reference ]">
                  <span class="search-result-list-item-name">{{ getValueToDisplay(result?.fullname_display_list) }}</span>
                  <span class="search-result-list-item-address">{{ getValueToDisplay(result?.residence_on_map?.place_name_display_list) }}</span>
                </div>
              </div>
              <button *ngIf="searchByAddressNextPage!=null" (click)="apiService.searchByAddressFetchNextPage()">{{ searchUITranslations?.loadMore }}</button>
            </div>
          </div>
        </div>
        <div *ngIf="!searchByQuery && !searchByAddress" class="search-result-no-active-search">
          <span>{{ searchUITranslations?.noActiveSearchQueries }}</span>
          <span>{{ searchUITranslations?.searchInstructions }}</span>
          <span>{{ searchUITranslations?.advancedSearchPromotion }}.</span>
          <button (click)="applicationState.setCurrentDisplay('advanced_search')">{{ searchUITranslations?.advancedSearch }}</button>
        </div>
      </mat-tab>
  </mat-tab-group>
  </div>
</div>
<!-- </div> -->
<div class="advanced-search-wrapper" [ngClass]="{'hidden': currentDisplay!='advanced_search'}">

<form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
  
  

  <div class="advanced-search-title-wrapper">{{ searchUITranslations?.advancedSearch | uppercase }}</div>
  <div class="advanced-search-content-wrapper">
    <div class="advanced-search-options-wrapper" *ngIf="searchByQuery">
      <div class="advanced-search-back-to-results-wrapper" (click)="applicationState.setCurrentDisplay('search');"><img src="/assets/back.svg">{{ searchUITranslations?.backToResults | titlecase }}</div>
      <div class="advanced-search-clear-search" (click)="clearSearch(true);">{{ searchUITranslations?.clearSearch | titlecase }}</div>
    </div>
  
    <div class="search-column-wrapper">
      <div class="search-field-grouping">
        <div class="search-grouping-title">
          {{ searchUITranslations?.name.toUpperCase() }}
        </div>
        <input type="text"  class="form-control" id="firstname" placeholder="{{searchUITranslations?.firstname}}" formControlName="firstname" name="firstname" [value]="searchForm.value.firstname">
        <input type="text"  class="form-control" id="lastname" placeholder="{{searchUITranslations?.lastname}}" formControlName="lastname" name="lastname" [value]="searchForm.value.lastname">
      </div>
      <div class="search-grouping-title">
        {{ searchUITranslations?.residence.toUpperCase()}}
      </div>
      <div class="date-title-search-selector-wrapper">
        <span class="field-group-title">{{ searchUITranslations?.residenceDate }}</span> 
        <div class="date-search-selector-wrapper">
        <mat-slide-toggle class="date-input-toggle"
                [labelPosition]="labelPosition"
                [checked]="dateFormatSwitchers.residence"
                [color]="sliderColor"
                (change)="dateFormatSwitchers.residence=!dateFormatSwitchers.residence"
                [disabled]="false">
                {{ searchUITranslations?.singleDate }}
              </mat-slide-toggle>
              {{ searchUITranslations?.dateRange }}
        </div>
      </div>
      <div formGroupName="residence" class="search-field-grouping">
        <div class="search-field-from-to-grouping">
          <div formGroupName="residence_date_from" class="search-date-grouping">
            <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="residence_date_from_day" placeholder="{{searchUITranslations?.day}}" formControlName="residence_date_from_day" name="residence_date_from_day">
            <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="residence_date_from_month" placeholder="{{searchUITranslations?.month}}" formControlName="residence_date_from_month" name="residence_date_from_month">
            <input type="number" maxlength="4" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="residence_date_from_year" placeholder="{{searchUITranslations?.year}}" formControlName="residence_date_from_year" name="residence_date_from_year">
          </div>
          <div class="search-field-date-divider" [ngClass]="{hidden: !dateFormatSwitchers.residence}">
            -
          </div>
          <div formGroupName="residence_date_to" class="search-date-grouping" [ngClass]="{hidden: !dateFormatSwitchers.residence}">
            <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="residence_date_to_day" placeholder="{{searchUITranslations?.day}}" formControlName="residence_date_to_day" name="residence_date_to_day" [value]="searchForm.value.residence.residence_date_to.residence_date_to_day">
            <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="residence_date_to_month" placeholder="{{searchUITranslations?.month}}" formControlName="residence_date_to_month" name="residence_date_to_month" [value]="searchForm.value.residence.residence_date_to.residence_date_to_month">
            <input type="number" maxlength="4" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="residence_date_to_year" placeholder="{{searchUITranslations?.year}}" formControlName="residence_date_to_year" name="residence_date_to_year" [value]="searchForm.value.residence.residence_date_to.residence_date_to_year">
          </div>
        </div>
        <div formGroupName="street_streetnumber" class="street_streetnumber">
          <input type="text"  class="form-control" id="street" placeholder="{{searchUITranslations?.street}}" formControlName="street" name="street" [value]="searchForm.value.residence.street_streetnumber.street">
          <input type="text"  class="form-control" id="streetnumber" placeholder="{{searchUITranslations?.streetnumber}}" formControlName="streetnumber" name="streetnumber" [value]="searchForm.value.residence.street_streetnumber.streetnumber">
        </div>
        <input type="text"  class="form-control" id="community" placeholder="{{searchUITranslations?.community}}" formControlName="community" name="community" [value]="searchForm.value.residence.community">
        <input type="text"  class="form-control" id="quarter" placeholder="{{searchUITranslations?.quarter}}" formControlName="quarter" name="quarter">
        <input type="text"  class="form-control" id="district" placeholder="{{searchUITranslations?.district}}" formControlName="district" name="district">
        <input type="text"  class="form-control" id="state" placeholder="{{searchUITranslations?.state}}" formControlName="state" name="state">
        <input type="text"  class="form-control" id="country" placeholder="{{searchUITranslations?.country}}" formControlName="country" name="country">
    </div>
  </div>

  <div class="search-column-wrapper">
    <div formGroupName="birth_death" class="search-field-grouping">
      <div class="search-grouping-title">
        {{ searchUITranslations?.birthDeath.toUpperCase()}}
      </div>
      <div class="date-title-search-selector-wrapper">
        <span class="field-group-title">{{ searchUITranslations?.birthDate }}</span> 
        <div class="date-search-selector-wrapper">
        <mat-slide-toggle class="date-input-toggle"
                [labelPosition]="labelPosition"
                [checked]="dateFormatSwitchers.birth"
                [color]="sliderColor"
                (change)="dateFormatSwitchers.birth=!dateFormatSwitchers.birth"
                [disabled]="false">
                {{ searchUITranslations?.singleDate }}
              </mat-slide-toggle>
              {{ searchUITranslations?.dateRange }}
        </div>
      </div>
      <div formGroupName="birth">
        <div class="search-field-from-to-grouping">
          <div formGroupName="birth_date_from" class="search-date-grouping">
            <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="birth_date_from_day" placeholder="{{searchUITranslations?.day}}" formControlName="birth_date_from_day" name="birth_date_from_day">
            <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="birth_date_from_month" placeholder="{{searchUITranslations?.month}}" formControlName="birth_date_from_month" name="birth_date_from_month">
            <input type="number" maxlength="4" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="birth_date_from_year" placeholder="{{searchUITranslations?.year}}" formControlName="birth_date_from_year" name="birth_date_from_year">
          </div>
          <div class="search-field-date-divider" [ngClass]="{hidden: !dateFormatSwitchers.birth}">
            -
          </div>
          <div formGroupName="birth_date_to" class="search-date-grouping" [ngClass]="{hidden: !dateFormatSwitchers.birth}">
            <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="birth_date_to_day" placeholder="{{searchUITranslations?.day}}" formControlName="birth_date_to_day" name="birth_date_to_day" [value]="searchForm.value.birth_death.birth.birth_date_to.birth_date_to_day">
            <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="birth_date_to_month" placeholder="{{searchUITranslations?.month}}" formControlName="birth_date_to_month" name="birth_date_to_month" [value]="searchForm.value.birth_death.birth.birth_date_to.birth_date_to_month">
            <input type="number" maxlength="4" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="birth_date_to_year" placeholder="{{searchUITranslations?.year}}" formControlName="birth_date_to_year" name="birth_date_to_year" [value]="searchForm.value.birth_death.birth.birth_date_to.birth_date_to_year">
          </div>
        </div>
        <input type="text"  class="form-control" id="birth_place" placeholder="{{searchUITranslations?.birthPlace}}" formControlName="birth_place" name="birth_place">
      </div>
      <div class="date-title-search-selector-wrapper">
        <span class="field-group-title">{{ searchUITranslations?.deathDate }}</span> 
        <div class="date-search-selector-wrapper">
        <mat-slide-toggle class="date-input-toggle"
                [labelPosition]="labelPosition"
                [checked]="dateFormatSwitchers.death"
                [color]="sliderColor"
                (change)="dateFormatSwitchers.death=!dateFormatSwitchers.death"
                [disabled]="false">
                {{ searchUITranslations?.singleDate }}
              </mat-slide-toggle>
              {{ searchUITranslations?.dateRange }}
        </div>
      </div>
      <div formGroupName="death">
        <div class="search-field-from-to-grouping">
          <div formGroupName="death_date_from" class="search-date-grouping">
            <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="death_date_from_day" placeholder="{{searchUITranslations?.day}}" formControlName="death_date_from_day" name="death_date_from_day">
            <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="death_date_from_month" placeholder="{{searchUITranslations?.month}}" formControlName="death_date_from_month" name="death_date_from_month">
            <input type="number" maxlength="4" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="death_date_from_year" placeholder="{{searchUITranslations?.year}}" formControlName="death_date_from_year" name="death_date_from_year">
          </div>
          <div class="search-field-date-divider" [ngClass]="{hidden: !dateFormatSwitchers.death}">
            -
          </div>
          <div formGroupName="death_date_to" class="search-date-grouping" [ngClass]="{hidden: !dateFormatSwitchers.death}">
            <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="death_date_to_day" placeholder="{{searchUITranslations?.day}}" formControlName="death_date_to_day" name="death_date_to_day" [value]="searchForm.value.birth_death.death.death_date_to.death_date_to_day">
            <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="death_date_to_month" placeholder="{{searchUITranslations?.month}}" formControlName="death_date_to_month" name="death_date_to_month" [value]="searchForm.value.birth_death.death.death_date_to.death_date_to_month">
            <input type="number" maxlength="4" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="death_date_to_year" placeholder="{{searchUITranslations?.year}}" formControlName="death_date_to_year" name="death_date_to_year" [value]="searchForm.value.birth_death.death.death_date_to.death_date_to_year">
          </div>
        </div>
        <input type="text"  class="form-control" id="death_place" placeholder="{{searchUITranslations?.deathPlace}}" formControlName="death_place" name="death_place">
      </div>
    </div>
    <div formGroupName="deportation" class="search-field-grouping">
      <div class="search-grouping-title">
        {{ searchUITranslations?.deportation.toUpperCase() }}
      </div>
      <div class="date-title-search-selector-wrapper">
        <span class="field-group-title">{{ searchUITranslations?.deportationDate }}</span> 
        <div class="date-search-selector-wrapper">
        <mat-slide-toggle class="date-input-toggle"
                [labelPosition]="labelPosition"
                [checked]="dateFormatSwitchers.deportation"
                [color]="sliderColor"
                (change)="dateFormatSwitchers.deportation=!dateFormatSwitchers.deportation"
                [disabled]="false">
                {{ searchUITranslations?.singleDate }}
              </mat-slide-toggle>
              {{ searchUITranslations?.dateRange }}
        </div>
      </div>
      <div class="search-field-from-to-grouping">
        <div formGroupName="deportation_date_from" class="search-date-grouping">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="deportation_date_from_day" placeholder="{{searchUITranslations?.day}}" formControlName="deportation_date_from_day" name="deportation_date_from_day">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="deportation_date_from_month" placeholder="{{searchUITranslations?.month}}" formControlName="deportation_date_from_month" name="deportation_date_from_month">
          <input type="number" maxlength="4" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="deportation_date_from_year" placeholder="{{searchUITranslations?.year}}" formControlName="deportation_date_from_year" name="deportation_date_from_year">
        </div>
        <div class="search-field-date-divider" [ngClass]="{hidden: !dateFormatSwitchers.deportation}">
          -
        </div>
        <div formGroupName="deportation_date_to" class="search-date-grouping" [ngClass]="{hidden: !dateFormatSwitchers.deportation}">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="deportation_date_to_day" placeholder="{{searchUITranslations?.day}}" formControlName="deportation_date_to_day" name="deportation_date_to_day" [value]="searchForm.value.deportation.deportation_date_to.deportation_date_to_day">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="deportation_date_to_month" placeholder="{{searchUITranslations?.month}}" formControlName="deportation_date_to_month" name="deportation_date_to_month" [value]="searchForm.value.deportation.deportation_date_to.deportation_date_to_month">
          <input type="number" maxlength="4" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="deportation_date_to_year" placeholder="{{searchUITranslations?.year}}" formControlName="deportation_date_to_year" name="deportation_date_to_year" [value]="searchForm.value.deportation.deportation_date_to.deportation_date_to_year">
        </div>
      </div>
      <input type="text"  class="form-control" id="deportation_place_from" placeholder="{{searchUITranslations?.deportationPlaceFrom}}" formControlName="deportation_place_from" name="deportation_place_from">
      <input type="text"  class="form-control" id="deportation_place" placeholder="{{searchUITranslations?.deportationPlace}}" formControlName="deportation_place" name="deportation_place">
    </div>
    <div formGroupName="emigration" class="search-field-grouping">
      <div class="search-grouping-title">
        {{ searchUITranslations?.emigration.toUpperCase() }}
      </div>
      <div class="date-title-search-selector-wrapper">
        <span class="field-group-title">{{ searchUITranslations?.emigrationDate }}</span> 
        <div class="date-search-selector-wrapper">
        <mat-slide-toggle class="date-input-toggle"
                [labelPosition]="labelPosition"
                [checked]="dateFormatSwitchers.emigration"
                [color]="sliderColor"
                (change)="dateFormatSwitchers.emigration=!dateFormatSwitchers.emigration"
                [disabled]="false">
                {{ searchUITranslations?.singleDate }}
              </mat-slide-toggle>
              {{ searchUITranslations?.dateRange }}
        </div>
      </div>
      <div class="search-field-from-to-grouping">
        <div formGroupName="emigration_date_from" class="search-date-grouping">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="emigration_date_from_day" placeholder="{{searchUITranslations?.day}}" formControlName="emigration_date_from_day" name="emigration_date_from_day">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="emigration_date_from_month" placeholder="{{searchUITranslations?.month}}" formControlName="emigration_date_from_month" name="emigration_date_from_month">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="emigration_date_from_year" placeholder="{{searchUITranslations?.year}}" formControlName="emigration_date_from_year" name="emigration_date_from_year">
        </div>
        <div class="search-field-date-divider" [ngClass]="{hidden: !dateFormatSwitchers.emigration}">
          -
        </div>
        <div formGroupName="emigration_date_to" class="search-date-grouping" [ngClass]="{hidden: !dateFormatSwitchers.emigration}">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="emigration_date_to_day" placeholder="{{searchUITranslations?.day}}" formControlName="emigration_date_to_day" name="emigration_date_to_day" [value]="searchForm.value.emigration.emigration_date_to.emigration_date_to_day">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="emigration_date_to_month" placeholder="{{searchUITranslations?.month}}" formControlName="emigration_date_to_month" name="emigration_date_to_month" [value]="searchForm.value.emigration.emigration_date_to.emigration_date_to_month">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="emigration_date_to_year" placeholder="{{searchUITranslations?.year}}" formControlName="emigration_date_to_year" name="emigration_date_to_year" [value]="searchForm.value.emigration.emigration_date_to.emigration_date_to_year">
        </div>
      </div>
      <input type="text"  class="form-control" id="emigration_place_from" placeholder="{{searchUITranslations?.emigrationPlaceFrom}}" formControlName="emigration_place_from" name="emigration_place_from">
      <input type="text"  class="form-control" id="emigration_place" placeholder="{{searchUITranslations?.emigrationPlace}}" formControlName="emigration_place" name="emigration_place">
    </div>
  </div>

  <div class="search-column-wrapper">
    <div formGroupName="expulsion" class="search-field-grouping">
      <div class="search-grouping-title">
        {{ searchUITranslations?.expulsion.toUpperCase() }}
      </div>
      <div class="date-title-search-selector-wrapper">
        <span class="field-group-title">{{ searchUITranslations?.expulsionDate }}</span> 
        <div class="date-search-selector-wrapper">
        <mat-slide-toggle class="date-input-toggle"
                [labelPosition]="labelPosition"
                [checked]="dateFormatSwitchers.expulsion"
                [color]="sliderColor"
                (change)="dateFormatSwitchers.expulsion=!dateFormatSwitchers.expulsion"
                [disabled]="false">
                {{ searchUITranslations?.singleDate }}
              </mat-slide-toggle>
              {{ searchUITranslations?.dateRange }}
        </div>
      </div>
      <div class="search-field-from-to-grouping">
        <div formGroupName="expulsion_date_from" class="search-date-grouping">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="expulsion_date_from_day" placeholder="{{searchUITranslations?.day}}" formControlName="expulsion_date_from_day" name="expulsion_date_from_day">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="expulsion_date_from_month" placeholder="{{searchUITranslations?.month}}" formControlName="expulsion_date_from_month" name="expulsion_date_from_month">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="expulsion_date_from_year" placeholder="{{searchUITranslations?.year}}" formControlName="expulsion_date_from_year" name="expulsion_date_from_year">
        </div>
        <div class="search-field-date-divider" [ngClass]="{hidden: !dateFormatSwitchers.expulsion}">
          -
        </div>
        <div formGroupName="expulsion_date_to" class="search-date-grouping" [ngClass]="{hidden: !dateFormatSwitchers.expulsion}">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="expulsion_date_to_day" placeholder="{{searchUITranslations?.day}}" formControlName="expulsion_date_to_day" name="expulsion_date_to_day" [value]="searchForm.value.expulsion.expulsion_date_to.expulsion_date_to_day">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="expulsion_date_to_month" placeholder="{{searchUITranslations?.month}}" formControlName="expulsion_date_to_month" name="expulsion_date_to_month" [value]="searchForm.value.expulsion.expulsion_date_to.expulsion_date_to_month">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="expulsion_date_to_year" placeholder="{{searchUITranslations?.year}}" formControlName="expulsion_date_to_year" name="expulsion_date_to_year" [value]="searchForm.value.expulsion.expulsion_date_to.expulsion_date_to_year">
        </div>
      </div>
      <input type="text"  class="form-control" id="emigration_place" placeholder="{{searchUITranslations?.expulsionPlace}}" formControlName="expulsion_place" name="expulsion_place">
    </div>
    <div formGroupName="imprisonment" class="search-field-grouping">
      <div class="search-grouping-title">
        {{ searchUITranslations?.imprisonment.toUpperCase() }}
      </div>
      <div class="date-title-search-selector-wrapper">
        <span class="field-group-title">{{ searchUITranslations?.imprisonmentDate }}</span> 
        <div class="date-search-selector-wrapper">
        <mat-slide-toggle class="date-input-toggle"
                [labelPosition]="labelPosition"
                [checked]="dateFormatSwitchers.imprisonment"
                [color]="sliderColor"
                (change)="dateFormatSwitchers.imprisonment=!dateFormatSwitchers.imprisonment"
                [disabled]="false">
                {{ searchUITranslations?.singleDate }}
              </mat-slide-toggle>
              {{ searchUITranslations?.dateRange }}
        </div>
      </div>
      <div class="search-field-from-to-grouping">
        <div formGroupName="imprisonment_date_from" class="search-date-grouping">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="imprisonment_date_from_day" placeholder="{{searchUITranslations?.day}}" formControlName="imprisonment_date_from_day" name="imprisonment_date_from_day">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="imprisonment_date_from_month" placeholder="{{searchUITranslations?.month}}" formControlName="imprisonment_date_from_month" name="imprisonment_date_from_month">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="imprisonment_date_from_year" placeholder="{{searchUITranslations?.year}}" formControlName="imprisonment_date_from_year" name="imprisonment_date_from_year">
        </div>
        <div class="search-field-date-divider" [ngClass]="{hidden: !dateFormatSwitchers.imprisonment}">
          -
        </div>
        <div formGroupName="imprisonment_date_to" class="search-date-grouping" [ngClass]="{hidden: !dateFormatSwitchers.imprisonment}">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-day" inputmode="numeric" min="1" max="31" id="imprisonment_date_to_day" placeholder="{{searchUITranslations?.day}}" formControlName="imprisonment_date_to_day" name="imprisonment_date_to_day" [value]="searchForm.value.imprisonment.imprisonment_date_to.imprisonment_date_to_day">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-month" inputmode="numeric" min="1" max="12" id="imprisonment_date_to_month" placeholder="{{searchUITranslations?.month}}" formControlName="imprisonment_date_to_month" name="imprisonment_date_to_month" [value]="searchForm.value.imprisonment.imprisonment_date_to.imprisonment_date_to_month">
          <input type="number" maxlength="2" (keyup)="onDigitInput($event)" class="form-control search-field-year" inputmode="numeric" min="1800" max="2100" id="imprisonment_date_to_year" placeholder="{{searchUITranslations?.year}}" formControlName="imprisonment_date_to_year" name="imprisonment_date_to_year" [value]="searchForm.value.imprisonment.imprisonment_date_to.imprisonment_date_to_year">
        </div>
      </div>
      <input type="text"  class="form-control" id="imprisonment_place" placeholder="{{searchUITranslations?.imprisonmentPlace}}" formControlName="imprisonment_place" name="imprisonment_place">
    </div>

    <div class="search-tips-wrapper">
      <div class="search-tips-title">
        <span>{{ searchUITranslations?.searchTips }}:</span>
      </div>
      <ul class="search-tips">
        <li class="search-tip" *ngFor="let searchTip of searchUITranslations?.searchTipsList">
          <span>{{ searchTip }}</span>
        </li>
      </ul>
      <div class="search-tip-link">
        <a href="/guide"><span>{{searchUITranslations?.userGuide}}</span></a>
      </div>
    </div>


  </div>
</div>

<input type="submit" value="{{ searchUITranslations?.search }}" (click)="applicationState.setCurrentDisplay('search')">
</form>
</div>
