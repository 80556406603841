<!-- <div class="menu-bar hidden" [ngClass]="{ 'hidden': embeddedView, disappear: !pagesAreHidden }">
  <div class="menu-bar-item menu-bar-icon-wrapper menu-bar-left" (click)="applicationState.togglePagesAreHidden()">
    <img src="/assets/information.svg" class="menu-bar-icon information-icon">
  </div>
  <div class="menu-bar-item menu-bar-icon-wrapper menu-bar-left search-icon-wrapper" (click)="applicationState.toggleSearchIsHidden()">
    <img src="/assets/search.svg" class="menu-bar-icon search-icon" [ngClass]="{ 'count-shown': resultCount>0 }">
    <div class="search-result-count-pill" [ngClass]="{ 'hidden': (!searchIsHidden || resultCount==0) }">
      {{ resultCount }}
    </div>
  </div>
  <div class="menu-bar-item menu-bar-title" (click)="applicationState.togglePagesAreHidden()">
    Mapping the Lives
  </div>
  <div class="menu-bar-icon-wrapper menu-bar-right" (click)="applicationState.toggleLanguageSwitchIsHidden()">
    <img src="/assets/translations.svg" class="menu-bar-icon">
  </div>
</div> -->
<!-- <div class="language-switch-wrapper" [ngClass]="{ hidden: languageSwitchIsHidden }" (click)="applicationState.toggleLanguageSwitchIsHidden()">
  <div class="language-switch">
    <div (click)="applicationState.toggleLanguageSwitchIsHidden()">
      <img src="/assets/close.svg" class="language-switch-close-icon" (click)="applicationState.toggleLanguageSwitchIsHidden()">
    </div>
    <span>
      {{ uiTranslations.switchLanguage }}
    </span>
      <div *ngFor="let language of languageChoices" (click)="setLanguageCode(language.value)">
        <span class="language-choices-item" [ngClass]="{ selected: isCurrentLanguage(language.value) }">{{ language.display }}</span>
      </div>
  </div>
</div> -->


<div class="outer-wrapper" [ngClass]="{ 'static-bg': !consentGiven }">

  <div class="map-overlay-left-wrapper" [ngClass]="{expand: leftOverlayFullscreen, 'mobile-view': applicationState.mobileView, 'minimized': currentDisplay=='map', 'menu-open': menuOpen, 'support-banner-displayed':!supportUsBannerHidden}">
    <!-- <app-menu></app-menu> -->

    <div class="menu-bar">
      <div class="menu-bar-item menu-bar-left" (click)="applicationState.setMenuOpen(true);">
        <img src="/assets/menu.svg" class="menu-bar-icon information-icon" [ngClass]="{ 'inverted': menuOpen }">
      </div>
      <div class="menu-bar-item menu-bar-title" [ngClass]="{hidden: currentDisplay=='landing_page'}" (click)="applicationState.setCurrentDisplay('landing_page')" [routerLink]="[ '/' ]">
        Mapping the Lives
      </div>
      <div class="menu-bar-item menu-bar-icon-wrapper menu-bar-right search-icon-wrapper" [ngClass]="{ 'hidden': currentDisplay!='map'}" (click)="applicationState.setCurrentDisplay('search')">
        <img src="/assets/search.svg" class="menu-bar-icon search-icon" [ngClass]="{ 'count-shown': resultCount>0, 'search-in-progress': searchInProgress }">
        <div class="search-result-count-pill" [ngClass]="{ 'hidden': resultCount<=0 }">
          {{ resultCount }}
        </div>
      </div>
      <div class="menu-bar-icon-wrapper" (click)="applicationState.setCurrentDisplay('close_left_overlay')" [ngClass]="{hidden: currentDisplay=='map'}">
        <!-- <span class="close-page-text" [ngClass]="{'hidden': !(slug!='' && applicationState.mobileView && applicationState.leftOverlayFullscreen) }">{{ uiTranslations.browseMap }}</span> -->
        <img class="menu-bar-icon close-icon" src="/assets/close.svg">
      </div>
    </div>

    <app-menu menuOpen="menuOpen" languageCode="languageCode"></app-menu>

    <div class="content-wrapper" [ngClass]="{'fade-in-content': (currentDisplay!='landing_page' && currentDisplay!='map'), hidden: !(currentDisplay!='map' && !rightOverlayFullscreen)}">
      <app-search></app-search>
    </div>

    <div *ngIf="currentDisplay=='landing_page'" class="langingpage">
      <div class="landing-page-header">
        <ng-element *ngIf="cooperationCredits">
          <span class="landing-page-cooperation-partner">{{ getValueToDisplay(cooperationPartnerName) }}</span>
          <span class="landing-page-cooperation-credit">{{ uiTranslations?.inCooperationWith }}</span>
        </ng-element>
        <span class="landing-page-title">Mapping the Lives</span>
        <span class="landing-page-subtitle">{{ uiTranslations?.landingPage.subtitle }}</span>
        <span class="landing-page-description">{{ uiTranslations?.landingPage.description }}</span>
      </div>
      <button class="button" (click)="applicationState.setCurrentDisplay('search')" [ngClass]="{hidden: !uiTranslations?.landingPage?.buttonText}">{{ uiTranslations?.landingPage?.buttonText }}</button>
    </div>

    <div class="map-overlay-left-footer" [ngClass]="{hidden: currentDisplay=='map'}">
      <span>{{ uiTranslations?.mappingTheLivesShortDescriptionFooter }}</span>
      <span>{{ uiTranslations?.aProjectBy }} <a href="{{ uiTranslations?.tracingThePastURL }}" target=”_blank”>Tracing the Past e.V.</a></span>
    </div>
  </div>

  <div class="map-overlay-right-wrapper" [ngClass]="{hidden: (currentDisplay!='person' && currentDisplay!='page'), fullscreen: rightOverlayFullscreen, 'support-banner-displayed':!supportUsBannerHidden}">
    <app-menu personFullscreen=true *ngIf="rightOverlayFullscreen"></app-menu>
    <div class="menu-header-wrapper" *ngIf="rightOverlayFullscreen">
      <div class="menu-bar-icon-wrapper menu-bar-title menu-bar-left" (click)="applicationState.setMenuOpen(true)" [ngClass]="{'mobile-view': applicationState.mobileView}">
        <img src="/assets/menu.svg" class="menu-bar-icon information-icon inverted">
      </div>
      <div class="menu-header-title">
        Mapping the Lives
      </div>
      <div class="menu-bar-icon-wrapper" (click)="closeRightOverlay()" [ngClass]="{hidden: currentDisplay=='map'}" [routerLink]="[rightOverlayRedirectToOnClose]">
        <img class="menu-bar-icon close-icon inverted" src="/assets/close.svg">
      </div>
    </div>
    <div class="menu-bar-icon-wrapper map-overlay-right-close" *ngIf="!rightOverlayFullscreen" [routerLink]="[rightOverlayRedirectToOnClose]" (click)="closeRightOverlay()">
      <img src="/assets/close.svg" class="menu-bar-icon map-overlay-right-close-icon inverted">
    </div>
    <app-person *ngIf="currentDisplay=='person'"></app-person>
    <div *ngIf="rightOverlayRedirectToOnClose!='/' && currentDisplay!='person'" (click)="closeRightOverlay()" [routerLink]="[rightOverlayRedirectToOnClose]" class="page-back-wrapper" [ngClass]="{fullwidth: rightOverlayFullscreen}">
      <img src="/assets/back_dark.svg" class="back-icon">{{  uiTranslations?.back }}
    </div>
    <app-page *ngIf="currentDisplay=='page'"></app-page>
    <div class="map-overlay-right-footer">
      <span>{{ uiTranslations?.mappingTheLivesShortDescriptionFooter }}</span>
      <span>© 2019 - {{currentYear}}  <a href="{{ uiTranslations?.tracingThePastURL }}" target=”_blank” style="color: #FFF;">Tracing the Past e.V.</a></span>
    </div>
  </div>
  <div class="consent-form-wrapper" [ngClass]="{ hidden: consentGiven }">
    <div class="scroll-wrapper">

      <span class="consent-form-title">{{ uiTranslations?.consentTitle }}</span>
      <div class="consent-form-google-maps-information-wrapper">
        <span class="consent-form-text">{{ uiTranslations?.googleMapsGDPRConsentText }}</span>
        <span class="consent-form-further-information">{{ uiTranslations?.googleMapsGDPRFurtherInformationText }}</span>
        <a target="_blank" href="{{ uiTranslations?.googleMapsGDPRFurtherInformationLink }}">{{ uiTranslations?.googleMapsGDPRFurtherInformationLink }}</a>
        <span>{{ uiTranslations?.ttpGDPRInformationText }} <a [routerLink]="['page/imprint']" (click)="applicationState.setCurrentDisplay('page')">{{ uiTranslations?.termsAndConditions }}</a></span>
      </div>
      <mat-slide-toggle class="remember-consent-toggle"
      [labelPosition]="'before'"
      [checked]="rememberConsent"
      [color]="'#00A9B0'"
      (change)="rememberConsent=!rememberConsent"
      [disabled]="false">
      {{ uiTranslations?.rememberConsent }}
      </mat-slide-toggle>
      <span class="consent-form-revoke-consent-information">{{ uiTranslations?.revokeConsentInformation }}</span>
      <button (click)="apiService.setConsentGiven(rememberConsent);">
        <span>{{ uiTranslations?.acceptRequiredServices }}</span>
      </button>
    </div>
  </div>
  <div class="support-us-bg-blur" [ngClass]="{ hidden: (supportUsBannerHidden || !supportUsBannerExpanded) }" (click)="supportUsBannerExpanded=false"></div>
  <div class="support-us-banner" [ngClass]="{ expanded: supportUsBannerExpanded, hidden:supportUsBannerHidden }">
    <div class="support-us-banner-header-wrapper" [ngClass]="{ 'support-us-banner-header-divider': supportUsBannerExpanded }" (click)="supportUsBannerExpanded=!supportUsBannerExpanded">
      <span class="support-us-banner-header">{{ uiTranslations?.supportUsHeader }}</span>
      <img src="/assets/back_dark.svg" class="support-banner-expand-icon" [ngClass]="{ 'expanded-icon': supportUsBannerExpanded }">
    </div>
    <div class="support-us-content" [ngClass]="{ 'content-expanded': supportUsBannerExpanded }">
      <div class="support-us-text" [innerHtml]="uiTranslations?.supportUsShortDescription"></div>
      <div class="support-us-buttons">
        <a href="{{ paypalDonationButton?.url }}" target="_blank" class="button paypal" (click)="supportUsBannerHidden=true">
            <img class="paypal-donation-button" src="{{ paypalDonationButton?.imgSrc }}" alt="{{ paypalDonationButton?.imgAlt }}">
        </a>
        <button class="button" [routerLink]="['/page/support-us']" (click)="supportUsBannerExpanded=false">{{ uiTranslations?.supportUsFurtherInformation }}</button>
        <hr>
        <button class="button" (click)="supportUsBannerHidden=true">{{ uiTranslations?.supportUsNotNow }}</button>
      </div>
      <div class="support-us-footer">
        <span>{{ uiTranslations?.mappingTheLivesShortDescriptionFooter }}</span>
        <span>{{ uiTranslations?.aProjectBy }} <a href="{{ uiTranslations?.tracingThePastURL }}" target=”_blank”>Tracing the Past e.V.</a></span>
      </div>
    </div>
  </div>
  <div class="map-blur" [ngClass]="{ hidden: !blurMap }" (click)="applicationState.setCurrentDisplay('close_blur')">
  </div>
  <app-map *ngIf="loadMap==true" [languageCode]="languageCode" [setZoom]="mapZoom" [setCenter]="mapCenter"></app-map>

</div>
