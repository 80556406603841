import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatTabsModule, MatTabGroup } from '@angular/material/tabs';
import { ApiService } from '../api.service';
import { ApplicationStateService } from '../application-state.service';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less']
})
export class SearchComponent implements AfterViewInit {
  @ViewChild( MatTabGroup ) tabGroup: MatTabGroup;
  public searchIsHidden: boolean = false;
  public advancedSearchActive: boolean = false;
  public searchInProgress = false;

  public languageCode = window.navigator.language;

  searchByQueryDataSubscription: any;
  searchByAddressDataSubscription: any;

  searchByQueryResults = [];
  searchByQueryResultCount = 0;
  searchByQueryNextPage = null;

  searchByAddressResults = [];
  searchByAddressResultCount = 0;
  searchByAddressNextPage = null;

  activeSearchParametersFromAdvancedSearch = false;

  selectedIndex = 0;
  currentLetter = '';
  locationNotFound = false;
  embeddedView = false;
  searchUITranslations;
  pagesAreHidden = true;
  mapUpdatesText = 'off';
  public currentDisplay;
  public searchFilterHidden = true;
  labelPosition = 'before';
  sliderColor = '#00A9B0';
  public filterActive = false;

  public searchByQuery = false;
  public searchByAddress = false;



  public consentGiven = false;

  public dateFormatSwitchers = {
    'residence': true,
    'birth': true,
    'death': true,
    'emigration': true,
    'imprisonment': true,
    'expulsion': true,
    'deportation': true,
  }


  onDigitInput(event){

    let element;
    if (event.code !== 'Backspace')
        if (event.target.value.length >= Number(event.target.attributes.getNamedItem('maxlength').value))
            element = event.srcElement.nextElementSibling;
 
     if (event.code === 'Backspace')
        if (event.target.value.length == 0)
             element = event.srcElement.previousElementSibling;

    console.log(element)
    if(element == null)
    {
        if (event.target.value.length >= Number(event.target.attributes.getNamedItem('maxlength').value)) {
          return false;
        }
        return;
      }
      else
      {
         element.focus();
      }
  }

  //   address: 'Address',
  //   name: 'name',
  //   firstname: 'Firstname(s)',
  //   lastname: 'Lastname or Maiden Name',
  //   residence: 'residence',
  //   street: 'Street',
  //   streetnumber: '#',
  //   community: 'City / Town',
  //   day: 'DD',
  //   month: 'MM',
  //   year: 'YYYY',
  //   quarter: 'Quarter of Residence',
  //   district: 'District of Residence',
  //   state: 'State of Residence',
  //   country: 'Country of Residence',
  //   birthDeath: 'birth / death',
  //   birthDate: 'Date of Birth',
  //   birthPlace: 'Place of Birth',
  //   deathDate: 'Date of Death',
  //   deathPlace: 'Place of Death',
  //   residenceDate: 'Date of Residence',
  //   deportation: 'deportation',
  //   deportationDate: 'Date of Deportation',
  //   deportationPlace: 'Place of Deportation',
  //   emigration: 'emigration',
  //   emigrationDate: 'Date of Emigration',
  //   emigrationPlace: 'Place of Emigration',
  //   expulsion: 'expulsion',
  //   expulsionDate: 'Date of Expulsion',
  //   expulsionPlace: 'Place of Expulsion',
  //   imprisonment: 'imprisonment',
  //   imprisonmentDate: 'Date of Imprisonment',
  //   imprisonmentPlace: 'Place of Imprisonment',
  //   search: 'search',
  //   advancedSearch: 'Advanced search',
  //   simpleSearch: 'Simple search',
  //   moreDetails: 'Have more details?',
  //   searchInstructions: 'You can also explore. Zoom into the map until you can see individual pins. Clicking a pin will show you a list of people who lived at that address.',
  //   location: 'location',
  //   locationInstructions: 'Search for location on map to zoom to.',
  //   zoomTo: 'zoom to',
  //   results: 'results',
  //   resultCount: 'person(s)',
  //   noResults: 'Currently no results.',
  //   filterByVisibleMap: 'restrict to map',
  //   clearSearch: 'clear search',
  //   searchTips: 'Search tips',
  //   loadMore: 'Load further results',
  //   locationNotFound: 'Location not found, please try a different spelling.',
  //   searchTipsList: [
  //       'Search for street and city only to find out who lived on that street',
  //       'Try searching with birth year only or spanning two years of birth',
  //       'If you are searching for a name with ä, ö, or ü, try searching respectively for a or ae, o or oe, u or ue',
  //   ]
  // };

  filterByVisibleMap = false;


  // checkIfNewLetter(name: string): boolean {
  //   if ( this.currentLetter === name.charAt(0).toUpperCase() ) {
  //     return false;
  //   }
  //   else
  //   {
  //     this.currentLetter = name.charAt(0).toUpperCase();
  //     return true;
  //   }
  // }

  public replaceNamingQualifierPlaceholder(name: string): string {
    return this.apiService.replaceNamingQualifierPlaceholder(name);
  }

  public toggleSearchFilter(): void {
    this.setSearchFilterHidden(!this.searchFilterHidden);
  }

  public setSearchFilterHidden(value): void {
    this.searchFilterHidden = value;
  }

  public setFilterByMap(value): void {
    this.filterByVisibleMap = value;
    this.filterActive = this.filterByVisibleMap;
    this.searchForm.get('filterByVisibleMap').setValue(this.filterByVisibleMap)
  }

  public toggleFilterByMap(): void {
    this.setFilterByMap(!this.filterByVisibleMap)
    this.onSubmit(false);
  }

  public getValueToDisplay(data): string {
    if ( data != undefined ) {
      if ( data.hasOwnProperty(this.languageCode) == true ) {
        return data[this.languageCode];
      }
      if ( data.hasOwnProperty("default") == true ) {
        return data.default;
      }
    }
    return "";
  }

  filterResultsFormClearButtonHidden = true;


  updateTimerFilter: any;
  updateIntervalFilter = 800;

  public filterResultsChanged(): void {
    clearTimeout(this.updateTimerFilter);
    this.updateTimerFilter = setTimeout(() => { this.onSubmitFilterResults(); }, this.updateIntervalFilter);
  }

  public filterResultsChangedAtAddress(): void {
    clearTimeout(this.updateTimerFilter);
    this.updateTimerFilter = setTimeout(() => { this.onSubmitFilterResultsAtAddress(); }, this.updateIntervalFilter);
  }


  public locationForm = new FormGroup({
    search_address: new FormControl('')});

  public filterResultsForm = new FormGroup({
    filterResults: new FormControl('')});
  
  public searchForm = new FormGroup({
    filterByVisibleMap: new FormControl(false),
    firstname: new FormControl(''),
    lastname: new FormControl(''),
    residence: new FormGroup({
      residence_date_from: new FormGroup({
        residence_date_from_day: new FormControl(''),
        residence_date_from_month: new FormControl(''),
        residence_date_from_year: new FormControl(''),
      }),
      residence_date_to: new FormGroup({
        residence_date_to_day: new FormControl(''),
        residence_date_to_month: new FormControl(''),
        residence_date_to_year: new FormControl(''),
      }),
      street_streetnumber: new FormGroup({
        street: new FormControl(''),
        streetnumber: new FormControl(''),
      }),
      community: new FormControl(''),
      quarter: new FormControl(''),
      district: new FormControl(''),
      state: new FormControl(''),
      country: new FormControl(''),
    }),
    birth_death: new FormGroup({
      birth: new FormGroup({
        birth_date_from: new FormGroup({
          birth_date_from_day: new FormControl(''),
          birth_date_from_month: new FormControl(''),
          birth_date_from_year: new FormControl(''),
        }),
        birth_date_to: new FormGroup({
          birth_date_to_day: new FormControl(''),
          birth_date_to_month: new FormControl(''),
          birth_date_to_year: new FormControl(''),
        }),
        birth_place: new FormControl(''),
      }),
      death: new FormGroup({
        death_date_from: new FormGroup({
          death_date_from_day: new FormControl(''),
          death_date_from_month: new FormControl(''),
          death_date_from_year: new FormControl(''),
        }),
        death_date_to: new FormGroup({
          death_date_to_day: new FormControl(''),
          death_date_to_month: new FormControl(''),
          death_date_to_year: new FormControl(''),
        }),
        death_place: new FormControl(''),
      })
    }),
    deportation: new FormGroup({
      deportation_date_from: new FormGroup({
        deportation_date_from_day: new FormControl(''),
        deportation_date_from_month: new FormControl(''),
        deportation_date_from_year: new FormControl(''),
      }),
      deportation_date_to: new FormGroup({
        deportation_date_to_day: new FormControl(''),
        deportation_date_to_month: new FormControl(''),
        deportation_date_to_year: new FormControl(''),
      }),
      deportation_place: new FormControl(''),
      deportation_place_from: new FormControl(''),
    }),
    emigration: new FormGroup({
      emigration_date_from: new FormGroup({
        emigration_date_from_day: new FormControl(''),
        emigration_date_from_month: new FormControl(''),
        emigration_date_from_year: new FormControl(''),
      }),
      emigration_date_to: new FormGroup({
        emigration_date_to_day: new FormControl(''),
        emigration_date_to_month: new FormControl(''),
        emigration_date_to_year: new FormControl(''),
      }),
      emigration_place: new FormControl(''),
      emigration_place_from: new FormControl(''),
    }),
    expulsion: new FormGroup({
      expulsion_date_from: new FormGroup({
        expulsion_date_from_day: new FormControl(''),
        expulsion_date_from_month: new FormControl(''),
        expulsion_date_from_year: new FormControl(''),
      }),
      expulsion_date_to: new FormGroup({
        expulsion_date_to_day: new FormControl(''),
        expulsion_date_to_month: new FormControl(''),
        expulsion_date_to_year: new FormControl(''),
      }),
      expulsion_place: new FormControl(''),
    }),
    imprisonment: new FormGroup({
      imprisonment_date_from: new FormGroup({
        imprisonment_date_from_day: new FormControl(''),
        imprisonment_date_from_month: new FormControl(''),
        imprisonment_date_from_year: new FormControl(''),
      }),
      imprisonment_date_to: new FormGroup({
        imprisonment_date_to_day: new FormControl(''),
        imprisonment_date_to_month: new FormControl(''),
        imprisonment_date_to_year: new FormControl(''),
      }),
      imprisonment_place: new FormControl(''),
    }),
  });

  constructor(public apiService: ApiService, private http: HttpClient, public applicationState: ApplicationStateService, public matTabNavBar: MatTabsModule) {
    this.apiService.languageCode.subscribe(
      data => {
        this.languageCode = data;
        // console.log("receiving language choice "+ data)
      }
    );

    this.apiService.activeSearchParametersFromAdvancedSearch.subscribe(
      data => {
        this.activeSearchParametersFromAdvancedSearch = data;
        // console.log("receiving language choice "+ data)
      }
    );

    this.apiService.cooperationPartnerData.subscribe(
      data => {
        if( data.hasOwnProperty('filters') && Object.keys(data.filters).length > 0 ) {
          this.searchForm.patchValue(data.filters);
          this.onSubmit()
        }
      }
    );
    this.searchByQueryDataSubscription = this.apiService.searchByQuery.subscribe(
      data => {
        if ( data?.length != undefined ) {
          this.searchByQueryResults = data;
          this.apiService.setSearchInProgress(false);
        }
      },
      error => console.error(error),
      () => {
          console.log('observer got a complete notification (search)')
        });

    this.searchByAddressDataSubscription = this.apiService.searchByAddress.subscribe(
      data => {
        if ( data?.length != undefined ) {
          this.searchByAddress = true;
          this.tabGroup.selectedIndex=2;
          this.searchByAddressResults = data;
          this.apiService.setSearchInProgress(false);
        }
      },
      error => console.error(error),
      () => {
          console.log('observer got a complete notification (search)')
        });
    this.applicationState.currentDisplay.subscribe(
        value => {
          this.currentDisplay = value;
        }
    )
    this.apiService.mapUpdates.subscribe(
        value => {
          if (value) {
            this.mapUpdatesText = "off";
          }
          else
          {
            this.mapUpdatesText = "on";
          }
    });

    this.apiService.uITextElements.subscribe(
      data => {
        if ( data.hasOwnProperty('search') == true && data.search.hasOwnProperty('firstname') == true ) {
          this.searchUITranslations = data.search;
        }
      }
    );
    this.apiService.searchByQueryResultCount.subscribe(
      data => {
        this.searchByQueryResultCount = data;
      }
    );
    this.apiService.searchByAddressResultCount.subscribe(
      data => {
        this.searchByAddressResultCount = data;
      }
    );
    this.apiService.embeddedView.subscribe(
      data => {
        this.embeddedView = data;
      }
    );

    this.apiService.consentGiven.subscribe(
      data => {
        this.consentGiven = data;
      }
    );

    this.apiService.searchInProgress.subscribe(
      value => {
        this.searchInProgress = value;
    });
    this.apiService.searchByQueryNextPage.subscribe(
      data => {
        this.searchByQueryNextPage = data;
      }
    );
  }


  // openBioPage(reference): void {
  //   router.navigate()
  // }

  // public loadNextPage(): void {
  //   this.apiService.fetchNextPage();
  // }

  clearSearch(complete=true): void {
    
    this.searchByQuery = false;
    this.searchByAddress = false;
    this.apiService.setSearchByAddressResultCount(0);
    if(complete) {
      this.apiService.clearSearchParameters();
      this.searchForm.reset();
      this.filterResultsForm.reset();
    }
    // this.apiService.setSearchParameters(this.searchForm.value);
    // this.tabGroup.selectedIndex = 0;
  }

  clearSearchAtAdress(): void {
    // this.searchForm.reset();
    this.searchByAddress = false;
    this.apiService.fetchSearchDataFromServer();
    // this.apiService.setSearchParameters(this.searchForm.value);
    // this.tabGroup.selectedIndex = 0;
  }


  

  ngAfterViewInit(): void {
    // if ( typeof google === 'object' && typeof google.maps === 'object') {

    // }
    this.tabGroup.selectedIndex = 0;
      // Create the search box and link it to the UI element.
    // this.searchDataSubscription.subscribe(
    //       data => {
    //         if ( data.length != undefined ) {
    //           this.currentLetter = '';
    //           this.searchResults = data;
    //           this.tabGroup.selectedIndex = 2;
    //         }
    //       },
    //       error => console.error(error),
    //       () => {
    //           console.log('observer got a complete notification (search)')
    //         });

  }

  clearFilterResults(): void {
    this.filterResultsForm.reset();
    this.onSubmitFilterResults();
  }

  clearFilterResultsAtAdress(): void {
    this.filterResultsForm.reset();
    this.onSubmitFilterResultsAtAddress();
  }

  onSubmitFilterResults(): void{
    let values = this.searchForm.value;
    values['filterResults'] = this.filterResultsForm.value.filterResults;
    let performSearch = false;
    if ( values['filterResults'] != null && values['filterResults'].length > 0 ){
      this.filterResultsFormClearButtonHidden = false;
      if (values['filterResults'].length > 2) {
        performSearch = true;
      }
    }
    else
    {
      performSearch = true;
      this.filterResultsFormClearButtonHidden = true;
    }
    if( performSearch ) {
      this.apiService.setSearchParameters(values);
    }
  }

  onSubmitFilterResultsAtAddress(): void{
    let values = this.searchForm.value;
    values['filterResults'] = this.filterResultsForm.value.filterResults;
    let performSearch = false;
    if ( values['filterResults'] != null && values['filterResults'].length > 0 ){
      this.filterResultsFormClearButtonHidden = false;
      if (values['filterResults'].length > 2) {
        performSearch = true;
      }
    }
    else
    {
      performSearch = true;
      this.filterResultsFormClearButtonHidden = true;
    }
    if( performSearch ) {
      this.apiService.setSearchParameters(values, false);
      this.apiService.searchAtCoordinates(null, true);
    }
  }

  onSubmit(fromSubmitButton=true) {
    if (this.applicationState.mobileView && fromSubmitButton) {
      this.setFilterByMap(true);
    }
    this.filterResultsForm.reset();
    this.tabGroup.selectedIndex=2;
    this.searchByQuery = true;
    
    this.filterResultsForm.value.filterResults = '';
    let values = this.searchForm.value;
    for (let dateFormat in this.dateFormatSwitchers) {
      if (this.dateFormatSwitchers[dateFormat] == false){
        if (dateFormat == 'birth' || dateFormat == 'death') {
          if(values['birth_death'][dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_day'].toString().length > 0 || values['birth_death'][dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_month'].toString().length > 0 || values['birth_death'][dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_year'].toString().length > 0) {
            values['birth_death'][dateFormat][dateFormat + '_date_to'][dateFormat + '_date_to_day'] = values['birth_death'][dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_day'];
            values['birth_death'][dateFormat][dateFormat + '_date_to'][dateFormat + '_date_to_month'] = values['birth_death'][dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_month'];
            values['birth_death'][dateFormat][dateFormat + '_date_to'][dateFormat + '_date_to_year'] = values['birth_death'][dateFormat][dateFormat + 'date_from'][dateFormat + '_date_from_year'];
          }
        }
        else
        {
          if(values[dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_day'].toString().length > 0 || values[dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_month'].toString().length > 0 || values[dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_year'].toString().length > 0) {
            values[dateFormat][dateFormat + '_date_to'][dateFormat + '_date_to_day'] = values[dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_day'];
            values[dateFormat][dateFormat + '_date_to'][dateFormat + '_date_to_month'] = values[dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_month'];
            values[dateFormat][dateFormat + '_date_to'][dateFormat + '_date_to_year'] = values[dateFormat][dateFormat + '_date_from'][dateFormat + '_date_from_year'];
          }
        }
      }
    }
    console.log(values)
    this.apiService.setSearchParameters(values);

    // console.log(this.searchForm.value)
  }

  onLocationFormSubmit() {
  //   this.http.get('https://maps.googleapis.com/maps/api/geocode/json?key=' + GOOGLE_MAPS_API_KEY + '&address=' + this.locationForm.value.search_address)
  //       .subscribe(
  //         data => {
  //           // console.log(this.locationForm.value.search_address, data)
  //           if ( data['results'].length > 0 ) {
  //             console.log(data['results'][0]['geometry']['viewport']);
  //             this.apiService.setFitToBounds(data['results'][0]['geometry']['viewport'])
  //             this.locationNotFound = false;
  //           }
  //           else
  //           {
  //             this.locationNotFound = true;
  //           }
  //         },
  //         error => {
  //             console.error(error),
  //             this.locationNotFound = true;
  //           },
  //       );
  }

}
