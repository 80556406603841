import { Component, HostListener, Renderer2, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ApplicationStateService } from './application-state.service';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

import { ApiService } from './api.service';


import { DOCUMENT } from '@angular/common';
import { MapComponent } from './map/map.component';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  @ViewChild(MapComponent , {static :false}) map : MapComponent;
  public cooperationCredits = false;
  public cooperationPartnerName = '';
  title = 'Mapping the Lives';
  searchIsHidden = false;
  resultCount = 0;
  languageCode = 'en';
  blurMap = false;
  embeddedView = false;
  pagesAreHidden = false;
  supportUsBannerHidden = false;
  fragmentSubscription: any;
  scrolledToAnchorOnInit = false
  person = false;
  currentRoute: string;
  page = {}
  personReference = '';
  supportUsBannerExpanded = false;
  supportUsBannerShown = false;
  public cooperationPartnerData = {};
  public paypalDonationButton;

  public searchInProgress = false;

  public rememberConsent = false;
  public consentGiven = false;
  public loadMap = false;

  currentYear = new Date().getFullYear();

  public slug = '';
  public menuOpen = false;
  public uiTranslations;
  public currentDisplay;
  public lastDisplay;

  public rightOverlayRedirectToOnClose = '/';

  public fragment = '';

  public rightOverlayFullscreen = false;
  public leftOverlayFullscreen = false;


  mapCenter = { lat: 50.3061856, lng: 12.3007083 };
  mapZoom = 6;

  @HostListener('window:resize', ['$event'])
    public onResize(event: Event) {
      this.applicationState.setWindowSize();
    }



  constructor(public applicationState: ApplicationStateService,
      public apiService: ApiService,
      private route: ActivatedRoute,
      private router: Router,
      private renderer: Renderer2,
      private changeDetector: ChangeDetectorRef,
      @Inject(DOCUMENT) private document: Document) {

        this.apiService.languageCode.subscribe(
          data => {
            if ( this.languageCode != data ){
              this.languageCode = data;
              if(this.map && this.consentGiven) {
                this.mapZoom = this.map.map.getZoom();
                let currentCenter = this.map.map.getCenter();
                this.mapCenter = {lat: currentCenter.lat(), lng:currentCenter.lng()};
                this.loadMap = false;
                this.changeDetector.detectChanges();
                this.loadMap = true;
              }
            }
          }
        );
        this.apiService.cooperationPartnerData.subscribe(
          data => {
            if ( data.hasOwnProperty('names') == true ) {
              this.cooperationCredits = data.show_cooperation_credit;
              this.cooperationPartnerName = data.names;
            } 
          }
        );

        this.router.events.subscribe((event: Event) => {
          if (event instanceof NavigationStart) {
              // Show progress spinner or progress bar
          }
   
          if (event instanceof NavigationEnd) {
              // Hide progress spinner or progress bar
              this.currentRoute = event.url;
              var url = event.url.substring(1);

              if (this.currentRoute) {
                this.fragmentSubscription = this.route.fragment.subscribe(fragment => this.fragment = fragment);
                setTimeout(() => {
                  this.fragmentSubscription.unsubscribe();
                  document.querySelector("#"+this.fragment)?.scrollIntoView({ block: 'start' });
                }, 800)
              }

              if ( url.split('?').length > 0 ) {
                url = url.split('?')[0];
              };

              if ( url.split('#').length > 0 ) {
                url = url.split('#')[0];
              };

              // console.log('url: '+ url);
              if (url != '' && url != null) {
                // console.log('url: '+ url);
                if ( url.substring(0,5) == 'page/') {
                  var slug = url.substring(5)
                  // console.log('slug ' + slug);
                  this.apiService.setPage(slug);
                  if ( this.currentDisplay == 'landing_page' ){
                    this.applicationState.setRightOverlayFullscreen(true);
                  }
                  this.applicationState.setCurrentDisplay('page');
                }
                else if ( url.substring(0,4) == 'bio/' ) {
                  if ( this.currentDisplay == 'landing_page' ){
                    this.applicationState.setCurrentDisplay('person');
                    this.applicationState.setRightOverlayFullscreen(true);
                  }
                  else
                  {
                    this.applicationState.setCurrentDisplay('person');
                  }
                  this.personReference = url.substring(4); // (+) converts string 'id' to a number
                  if ( this.personReference != '' ) {
                    this.apiService.setPersonReference(this.personReference);
                  } 
                  // In a real app: dispatch action to load the details here.
                }
              }
              else
              {
                 this.apiService.setPage("");
                 this.personReference = '';
              }
          }
   
          if (event instanceof NavigationError) {
               // Hide progress spinner or progress bar
   
              // Present error to user
          }
      });

    this.applicationState.getBlurMap().subscribe(
        value => {
          this.blurMap = value;
          if ( this.blurMap == false && this.supportUsBannerShown == false) {
            this.supportUsBannerExpanded = true;
            this.supportUsBannerShown = true;
          }
    });

    this.applicationState.menuOpen.subscribe(
      value => {
        this.menuOpen = value;
    });

    this.apiService.paypalDonationButton.subscribe(
      value => {
        this.paypalDonationButton = value;
    });

    this.apiService.consentGiven.subscribe(
      value => {
        this.consentGiven = value;
        this.loadMap = value;
    });

    this.applicationState.currentDisplay.subscribe(
        value => {
          this.slug = this.apiService.slug;
          this.currentDisplay = value;
          if ( this.currentDisplay == 'search' ) {
            this.router.navigate(['/']);
          }
          if( this.currentDisplay == 'page' && this.lastDisplay == 'person' ) {
            this.rightOverlayRedirectToOnClose = 'bio/' + this.personReference;
          }
          else
          {
            this.rightOverlayRedirectToOnClose = '/';
          }


        }
    );


    this.applicationState.rightOverlayFullscreen.subscribe(
      value => {
        this.rightOverlayFullscreen = value;
    });

    this.applicationState.leftOverlayFullscreen.subscribe(
      value => {
        this.leftOverlayFullscreen = value;
    });

    this.apiService.searchInProgress.subscribe(
      value => {
        this.searchInProgress = value;
    });

    this.apiService.searchByQueryResultCount.subscribe(
      value => {
        this.resultCount = value;
    });

    this.apiService.cooperationPartnerData.subscribe(
      value => {
        this.cooperationPartnerData = value;
        if (value.hasOwnProperty('zoom')) {
          this.mapZoom = value.zoom;
          this.mapCenter = value.center;
        }
    });

    this.apiService.searchByAddressResultCount.subscribe(
      value => {
        this.resultCount = value;
    });

    this.apiService.embeddedView.subscribe(
        value => {
          this.embeddedView = value;
    });

    this.applicationState.currentDisplay.subscribe(
      value => {
        this.currentDisplay = value;
    });

    this.applicationState.lastDisplay.subscribe(
      value => {
        this.lastDisplay = value;
    });


    

    this.route.queryParams.subscribe(
      params => {
        if ( params.language != undefined && params.language != this.languageCode ) {
            this.apiService.setLanguageCode(params.language);
        }
        if ( params.cooperationpartner != undefined && params.cooperationpartner != this.apiService.cooperationpartner && params.cooperationpartner.length == 36) {
            this.apiService.setCooperationPartner(params.cooperationpartner);
        }
      });

    this.apiService.uITextElements.subscribe(
      data => {
        if ( data.hasOwnProperty('general') == true ) {
          this.uiTranslations = data.general;
        }
      }
    );
  }

  closeRightOverlay(): void {
    if( this.currentDisplay == 'page' && this.lastDisplay == 'person') {
      this.applicationState.setCurrentDisplay('person');
    }
    else {
      this.applicationState.setCurrentDisplay('search');
      // if( this.rightOverlayFullscreen ) {
      //   this.applicationState.setRightOverlayFullscreen(false);
      // }
    }
  }

  public getValueToDisplay(data): string {
    if ( data != undefined ) {
      if ( data.hasOwnProperty(this.languageCode) == true ) {
        return data[this.languageCode];
      }
      if ( data.hasOwnProperty("default") == true ) {
        return data.default;
      }
    }
    return "";
  }

  ngAfterViewChecked(): void {
  //   if ( !this.scrolledToAnchorOnInit && this.fragment != ''){
  //     try {
  //       console.log("Trying this")
  //       document.querySelector("#"+this.fragment).scrollIntoView({ block: 'start' });
  //       this.scrolledToAnchorOnInit = true;
  //     } catch (e) {
  //       console.error(e, 'error');
  //     }
  //   }
  }
}
