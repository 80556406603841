import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageComponent } from './page/page.component';
import { PersonComponent } from './person/person.component';

const routes: Routes = [
  { path: 'page/:slug', component: PageComponent },
  { path: 'bio/:reference', component: PersonComponent },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
