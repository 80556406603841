import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler, Injectable } from "@angular/core";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleMapsModule } from '@angular/google-maps';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { Router, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import { AppComponent } from './app.component';
import { ApiService } from './api.service';
import { ApplicationStateService } from './application-state.service';
import { MapComponent } from './map/map.component';
import { PlaceComponent } from './place/place.component';
import { PersonComponent } from './person/person.component';
import { PageComponent } from './page/page.component';
import { SearchComponent } from './search/search.component';
import { MenuComponent } from './menu/menu.component';

import * as Sentry from "@sentry/browser";
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';

Sentry.init({
  dsn: "https://3c316239b7c144b0b00c131b9b777e62@sentry.dadaserver.com/13"
});

import { DomSanitizer } from '@angular/platform-browser'
import { PipeTransform, Pipe } from "@angular/core";

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    const eventId = Sentry.captureException(error.originalError || error);
    // Sentry.showReportDialog({ eventId });
  }
}


@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    PlaceComponent,
    PersonComponent,
    PageComponent,
    SearchComponent,
    MenuComponent,
    SafeHtmlPipe
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    MatTabsModule,
    MatDialogModule,
    MatSlideToggleModule,
    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxMatomoTrackerModule.forRoot({ trackerUrl: 'https://tracking.fox.co.at/', siteId: '33' }),
    NgxMatomoRouterModule,
  ],
  providers: [ApiService, ApplicationStateService, { provide: ErrorHandler, useClass: SentryErrorHandler }],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(router: Router, viewportScroller: ViewportScroller) {
    viewportScroller.setOffset([0, 120]);
    router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: Scroll) => {
      if (e.anchor) {
        // anchor navigation
        console.log("doing this");
        setTimeout(() => {
          console.log('e.anchor', e.anchor)
          viewportScroller.scrollToAnchor(e.anchor);
        })
      } else if (e.position) {
        // backward navigation
        viewportScroller.scrollToPosition(e.position);
      } else {
        // forward navigation
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
// export class AppModule { }
}