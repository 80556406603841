
<div class="person-wrapper">
  <div class="loading-spinner-wrapper" *ngIf="dataLoadingStatus==0"><div class="loading-spinner"><div></div><div></div><div></div><div></div></div></div>
    <div class="person-content-wrapper" *ngIf="dataLoadingStatus==1" [ngClass]="{fullwidth: currentDisplay=='person_fullscreen'}">
      <div class="person-wrapper-name" [ngClass]="{fullwidth: currentDisplay=='person_fullscreen'}">
        {{ person.fullname }}
      </div>
      <div class="spacer-element"></div>
      <div class="person-item person-details" *ngFor="let personDetail of person.biographicDetails">
        <div class="person-item-row" class="{{personDetail.cssClass}}">
          <span *ngIf="personDetail.name!=null" class="person-detail-name-highlight" >{{ personDetail.name }}:</span>
          <span *ngFor="let personDetailValue of personDetail.values; let j = index;">
            <ng-element *ngIf="j > 0">, </ng-element>
            <ng-element *ngIf="personDetailValue.hasOwnProperty('furtherInformationUrl'); else noFurtherInformation"><u class="further-information-link" [routerLink]="[ personDetailValue?.furtherInformationUrl, personDetailValue?.furtherInformationSlug]" fragment="{{personDetailValue?.furtherInformationAnchor}}">{{ personDetailValue.value }}<img src="/assets/info.svg" class="further-info-icon"></u></ng-element>
            <ng-template #noFurtherInformation>{{ personDetailValue.value }}</ng-template>
          </span>
        </div>
      </div>
      <div class="person-biography-wrapper" *ngFor="let biographyText of person.biographyTexts">
        <div class="person-biography-text" [innerHTML]="biographyText.text">
        </div>
        <div class="person-biography-continue" *ngIf="biographyText?.continueUrl">
            <a href="{{ biographyText?.continueUrl }}" target="_blank">{{ uiTranslations?.fulltextAvailableOnSourcesWebsite }} <img src="/assets/external_link.svg" class="external-link-icon"></a>
        </div>
        <span class="person-biography-source" *ngIf="biographyText.source">{{ uiTranslations?.source | titlecase }}: (<a (click)="scrollToSource(biographyText.source)">{{ biographyText.source }}</a>)</span>
      </div>
      

      <div class="person-events-wrapper">
        <div class="person-event-wrapper" *ngFor="let event of person.events">
          <span class="person-detail-header">{{ event.title }}</span>
          <div class="person-event event-details" *ngFor="let eventDetail of event.items">
            <div class="person-event-row" class="{{ eventDetail.cssClass }}" [ngClass]="{fullwidth: eventDetail.name==null}">
              <span *ngIf="eventDetail.name!=null" class="person-detail-name person-detail-name-highlight">{{ eventDetail.name }}:</span>
              <div class="event-detail-values" *ngFor="let eventDetailValue of eventDetail.values">
                <span class="person-detail-value">
                  {{ eventDetailValue.value }}{{ eventDetailValue.seperator }}
                </span>
                <span *ngIf="eventDetailValue.linebreak" class="person-detail-value-linebreak"></span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="person-alternate-details-wrapper person-item-fw" *ngIf="person.alternatePersonalDetails.length > 0">
        <span class="person-detail-header">{{ person.alternatePersonalDetailsTitle }}</span>
        <div class="person-alternate-detail-wrapper" *ngFor="let alternateDetail of person.alternatePersonalDetails">
          <div class="person-item-row" class="{{ alternateDetail?.cssClass }}">
            <span class="person-detail-name-highlight">{{ alternateDetail.name }}:</span>
            <div class="person-alternate-detail-values" *ngFor="let alternateDetailValue of alternateDetail.values; let i = index;">
              <span class="person-detail-value">
                <ng-element *ngIf="i>0">, </ng-element>{{ alternateDetailValue.value }}
              </span>
              <span *ngIf="eventDetailValue?.linebreak" class="person-detail-value-linebreak"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="person-annotations-wrapper person-item-fw" *ngIf="person.annotations.length > 0">
        <span class="person-detail-header">{{ person.annotationsTitle }}</span>
        <div class="person-annotation-wrapper" *ngFor="let annotation of person.annotations">
          <div class="person-annotation-row" class="{{ annotation.cssClass }}">
            <span class="person-detail-name-highlight">{{ annotation.name }}<ng-element *ngIf="annotation?.sourceNumber"> (<a (click)="scrollToSource(annotation?.sourceNumber)">{{ annotation?.sourceNumber }}</a>)</ng-element>:</span>
            <ng-element *ngIf="annotation.link">
              <a href="{{ annotation.link }}" target="_blank">{{ annotation.value }}
                <img src="/assets/external_link.svg" class="external-link-icon">
              </a>
            </ng-element>
            <ng-element *ngIf="!annotation.link">
              {{ annotation.value }}
            </ng-element>
          </div>
        </div>
      </div>

      <div class="person-source-wrapper person-item-fw">
        <span class="person-detail-header">{{ person.sourcesTitle }}</span>
        <div class="person-annotation-wrapper" *ngFor="let source of person.sources">
          <div class="person-source-row" id="source-{{ source.number }}" class="{{ source.cssClass }}">
            <div class="person-source-number">{{ source.number }}</div>
            <div class="person-source-name">
              {{ source.name }}
              <div *ngIf="source.link!='' && source.link!=null">
                <a href="{{ source.link }}" class="person-source-link" target="_blank">
                  {{ source.link }}
                  <img src="/assets/external_link.svg" class="external-link-icon">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="person-functions-wrapper">
        <div class="person-report-error-wrapper">
          <a href="mailto:error-report@tracingthepast.org?subject=Error in Biography {{ apiService.personReference }}">{{ uiTranslations?.reportAnError }}</a>
        </div>
        <div class="person-show-link-wrapper" (click)="showLink()">
          {{ uiTranslations?.showLink }}
        </div>
        <div class="person-link-wrapper" *ngIf="linkUrl!=''">
          <div class="person-link">{{ linkUrl }}</div> <span class="person-copy-link" (click)="copyLink()" [ngClass]="{hidden: copiedSucessfully}">{{  uiTranslations?.copyLink }}</span> <span class="person-coppied-sucessfully" [ngClass]="{hidden: !copiedSucessfully}">{{  uiTranslations?.copiedSucessfully }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="dataLoadingStatus==2">
      {{ uiTranslations?.errorLoading }}
    </div>
</div>
 