import { AfterViewInit, Component, Input, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, take, skipWhile } from 'rxjs/operators';
import { MapInfoWindow, MapMarker, GoogleMap, MapHeatmapLayer, GoogleMapsModule } from '@angular/google-maps'
import { ApplicationStateService } from '../application-state.service';


import { DOCUMENT } from '@angular/common';
import { Location } from '@angular/common';
const GOOGLE_MAPS_API_KEY = 'AIzaSyCPhxI8OdaiHW6j0NUeH8LGoT5lugDpnS4';
import { MapStyle } from './map.style';
import { ApiService } from '../api.service';

function tile2long(x,z) {
  return (x/Math.pow(2,z)*360-180);
 }
 function tile2lat(y,z) {
  var n=Math.PI-2*Math.PI*y/Math.pow(2,z);
  return (180/Math.PI*Math.atan(0.5*(Math.exp(n)-Math.exp(-n))));
 }

 export interface HeatMapData {
   lat: number;
   lng: number;
   weight: number;
 }

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.less'],
})

export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(GoogleMap, { static: false }) public map: GoogleMap
  @ViewChild(MapHeatmapLayer, { static: false }) public heatmap: MapHeatmapLayer;

  @Input() languageCode = 'en';
  @Input() setCenter = { lat: 50.3061856, lng: 12.3007083 };
  @Input() setZoom = 6;

  consentGiven = false;
  apiLoaded: Observable<boolean>;
  previous_zoom_level = 6;
  mapOptions: google.maps.MapOptions = {
      fullscreenControl: false,
      styles: MapStyle,
      minZoom: 2,
      mapTypeControl: false,
      streetViewControl: false,
  }
  public center = { lat: 50.3061856, lng: 12.3007083 };
  public zoom = this.previous_zoom_level;
  data_initialisation = true;
  autocomplete: any;
  mapDataSubscription: any;
  autoCompleteInitCalled = false;
  // languageCode = 'en';
  mapUITranslations: any;
  searchParametersChanged: any;

  uiTextElementsSubsriptionthis: any;
  cooperationParnerSubscription: any;
  getFitToBoundsSubscription: any;
  consentGivenSubscription: any;
  searchParametersChangedSubscription: any;
  uiTextElementsSubsription: any;


  // Map: google.maps.Map;
  // HeatMap: google.maps.visualization.MapHeatmapLayer;


  // heatmapData: any[] = [];
  // heatmapData$;

  // markerOptions: google.maps.MarkerOptions = {draggable: false};
  markers = [];



  heatmapOptions = {radius: 50, opacity: 0.55, dissipating: true, gradient:  [
      'rgba(65, 105, 225, 0)',
      'rgba(0, 56, 159, 1)',
      'rgba(0, 101, 202, 1)',
      'rgba(0, 143, 209, 1)',
      'rgba(0, 184, 177, 1)',
      'rgba(0, 223, 118, 1)',
      'rgba(94, 225, 0, 1)',
      'rgba(126, 242, 0, 1)',
      'rgba(175, 207, 0, 1)',
      'rgba(211, 157, 0, 1)',
      'rgba(227, 47, 0, 1)',
      'rgba(224, 0, 0, 1)',
    ]};

  heatmapData: any[] = [];


  constructor(private httpClient: HttpClient, private apiService: ApiService, public applicationState: ApplicationStateService, location: Location, @Inject(DOCUMENT) private document: Document) {
    // get the current path to determine if it is a url provided through a cooperationpartners iframe
    // this.setMapData();

    // this.apiService.languageCode.subscribe(
    //   data => {
    //     console.log(this.languageCode, data)
    //     if (data != this.languageCode){
    //       this.languageCode = data;
    //       console.log("doing this", data)
    //       this.apiLoaded = of(false);
    //       delete this.map;
    //       delete this.heatmap;
    //       var scripts = document.getElementsByTagName("script");
    //       for (var i = 0; i < scripts.length; i++) {
    //         if (scripts[i].src.startsWith('https://maps.googleapis.com/')) {
    //           scripts[i].remove()
    //         }
    //       }
    //       var stylesheets = document.getElementsByTagName("link");
    //       for (var i = 0; i < stylesheets.length; i++) {
    //         if (stylesheets[i].href.startsWith('https://fonts.googleapis.com/')) {
    //           stylesheets[i].remove()
    //         }
    //       }
    //       this.initGoogleMap();
    //     }
    //     else 
    //     {
    //       this.languageCode = data;
    //     }
    //   }
    // );
    


    
    

  }

  public ngOnDestroy() {
    console.log("Map destroy called");

    // Delete any existing maps api instance and script to reload language
    document.querySelectorAll('script[src^="https://maps.googleapis.com"]').forEach(v => {
      v.remove();
    });

    // Delete existing loaded map scripts
    if (typeof google !== 'undefined' && typeof google.maps !== 'undefined') {
      delete google.maps;
    }

    if (this.searchParametersChangedSubscription) {
      this.searchParametersChangedSubscription.unsubscribe();
    }
    if (this.consentGivenSubscription) {
      this.consentGivenSubscription.unsubscribe();
    }
    if (this.getFitToBoundsSubscription) {
      this.getFitToBoundsSubscription.unsubscribe();
    }
    if (this.cooperationParnerSubscription) {
      this.cooperationParnerSubscription.unsubscribe();
    }
    if (this.uiTextElementsSubsription) {
      this.uiTextElementsSubsription.unsubscribe();
    }
    if (this.mapDataSubscription) {
      this.mapDataSubscription.unsubscribe();
    }
    this.apiLoaded = of(false);
    delete this.map;
    delete this.heatmap;
  }


  initAutoCompleteField(): void {
    var checkExist = setInterval(() => {
      if(document.getElementById("search_address")){
        const input = document.getElementById("search_address") as HTMLInputElement;
        const options = {};

        this.autocomplete = new google.maps.places.Autocomplete(input, options);
        this.autocomplete.addListener("place_changed", () => {
          const place = this.autocomplete.getPlace();
          if (!place.geometry || !place.geometry.location) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            window.alert(this.mapUITranslations?.noPlace + "'" + place.name + "' " + this.mapUITranslations?.wasFound);
          }

          // If the place has a geometry, then present it on a map.
          if  (place?.geometry?.viewport ) {
            // console.log(place.geometry.viewport)
            this.map.fitBounds(place.geometry.viewport);
          } else {
            this.center = place.geometry.location;
            this.zoom = 17;
          }
        });
          
        this.autoCompleteInitCalled = true;
        clearInterval(checkExist);
      }
   }, 100);
      

  }

  ngOnInit(): void {
    this.center = this.setCenter;
    this.zoom = this.setZoom;
    this.initGoogleMap();

    this.uiTextElementsSubsription = this.apiService.uITextElements.subscribe(
      data => {
        if ( data.hasOwnProperty('map') == true) {
          this.mapUITranslations = data.map;
        }
      }
    );



    this.cooperationParnerSubscription = this.apiService.cooperationPartnerData.subscribe(
        data => {
          if (data.hasOwnProperty('center') == true) {
            console.log("Cooperation partner");
            this.center = data['center'];
            this.zoom = data['zoom'];
          }
      }
    );


    this.getFitToBoundsSubscription = this.apiService.getFitToBounds().subscribe(
          data => {
            if ( typeof google === 'object' && typeof google.maps === 'object') {
              var bounds = new google.maps.LatLngBounds();
              bounds.extend(data['northeast']);
              bounds.extend(data['southwest']);
              this.map.fitBounds(bounds);
            }
          },
          error => console.error(error),
          () => {
              console.log('observer got a complete notification (search)')
            });
    // var cooperationpartner_reference = this.route.snapshot.paramMap.get('cooperationpartner');
    // if ( cooperationpartner_reference != "" ) {
    //   this.apiService.setCooperationPartnerData(cooperationpartner_reference);
    // }
    // console.log(this.apiService.cooperationpartner_reference);
    //
    //     var change_map_focus_to = this.apiService.cooperationpartner_data;
    //     console.log(change_map_focus_to);
    //     this.zoom = change_map_focus_to.zoom;
    //     this.center = change_map_focus_to.center;
    // if (this.apiService.cooperationpartner_reference != "" ){
    // }

    this.consentGivenSubscription = this.apiService.consentGiven.subscribe( 
      value => {
      if ( value == true ) {
          this.initGoogleMap();
      }
    }); 

    


    this.searchParametersChangedSubscription = this.apiService.searchParametersChanged.subscribe( searchChanged => {
        console.log(searchChanged);
        if ( searchChanged == true ) {
            this.updateMap(null, true);
        }
    });

    var heatmapData = [];
    var markersTemp = [];
  }

  initGoogleMap(): void {
    this.apiLoaded = this.httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=' + GOOGLE_MAPS_API_KEY + '&language=' + this.languageCode + '&region=DE' + '&libraries=visualization,places', 'callback')
        .pipe(
          map(() => true),
          catchError(() => of(false)),
        );
  }

  ngAfterViewInit(): void {
    var heatmapData = [];
    var markersTemp = [];
    // pipe(
    //      skipWhile(value => !value), // skip null values
    //      take(1)).

    this.mapDataSubscription = this.apiService.getGeoPositionData().subscribe(
          data => {
            // data = data;
            // console.log("getting here")
            // console.log(data);
            // console.log(data._value);
            if ( typeof google === 'object' && typeof google.maps === 'object') {
              heatmapData = [];
              markersTemp = [];
              var zoomlevel = this.map.getZoom();
              // console.log('heatmap doc_count:' , data.events.filter_events.places.filter_places.heatmap )
              // console.log(this.zoom)
              if ( zoomlevel < 16 ) {
                // console.log(data)
                data?.viewport?.heatmap?.buckets?.forEach(
                  element => {
                      var location = new google.maps.LatLng(element.centroid.location.lat, element.centroid.location.lon);
                      heatmapData.push({location: location, weight: element.doc_count});
                  }
                );
                var max_count = 0;
                data?.viewport?.grid?.buckets?.forEach(
                  element => {
                      if (max_count == 0) {
                        max_count = element.doc_count;
                      }
                      // console.log(element);
                      // var tile = element.key.split('/');
                      // tile = tile.map(x => parseInt(x));
                      // var lat = tile2lat(tile[2],tile[0]);
                      // var lng = tile2long(tile[1],tile[0]);
                      // var location = new google.maps.LatLng(lat, lng);
                      var max_size;
                      if ( max_count < 100 ) {
                        max_size = 0.9;
                      }
                      else if ( max_count < 100 ) {
                        max_size = 1.1;
                      }
                      else if ( max_count < 1000 ) {
                        max_size = 1.4;
                      }
                      else if ( max_count < 10000 ) {
                        max_size = 1.8;
                      }
                      else if ( max_count < 100000 ) {
                        max_size = 2.5;
                      }
                      else if ( max_count < 1000000 ) {
                        max_size = 3;
                      }
                      // 1/2 pi r^2 = A
                      // => sqrt (2 A / pi)

                      var scale = max_size * Math.sqrt(2 * (element.doc_count / max_count) / 3.14);
                      if (scale < 0.7) {
                        scale = 0.7;
                      }
                      // console.log(scale)
                      const svgMarker = {
                         path: "M-15,0a15,15 0 1,0 30,0a15,15 0 1,0 -30,0",
                         fillColor: "#FFF",
                         fillOpacity: 0.9,
                         strokeWeight: 5,
                         strokeColor: "#9BBBBC",
                         rotation: 0,
                         scale: scale,
                         anchor: new google.maps.Point(0, 0),
                       };
                      var location = new google.maps.LatLng(element.centroid.location.lat, element.centroid.location.lon);
                      markersTemp.push({position: location, label: {text: element.doc_count.toString(), color: "rgb(0, 52, 113)"}, icon: svgMarker, type: 'cluster', zoom: zoomlevel});
                  });
              }
              else
              {
                var max_count = 0;
                data?.viewport?.heatmap?.buckets?.forEach(
                  element => {
                      if (max_count == 0) {
                        max_count = element.doc_count;
                      }
                      // console.log(element.doc_count);
                      // var tile = element.key.split('/');
                      // tile = tile.map(x => parseInt(x));
                      // var lat = tile2lat(tile[2],tile[0]);
                      // var lng = tile2long(tile[1],tile[0]);
                      var max_size;
                      if ( max_count < 10 ) {
                        max_size = 1.5;
                      }
                      else if ( max_count < 100 ) {
                        max_size = 1.75;
                      }
                      else if ( max_count < 1000 ) {
                        max_size = 2;
                      }
                      else if ( max_count < 10000 ) {
                        max_size = 3;
                      }
                      else if ( max_count > 10000 ) {
                        max_size = 4;
                      }
                      var scale = max_size * Math.sqrt(2 * (element.doc_count / max_count) / 3.14);
                      if (scale < 1) {
                        scale = 1;
                      }
                      // console.log(scale)
                       var svgMarkerPlace = {
                          path: "M-5,0a5,5 0 1,0 10,0a5,5 0 1,0 -10,0",
                          fillColor: "#FFFFFF",
                          fillOpacity: 0.9,
                          strokeWeight: 5,
                          strokeColor: "#000000",
                          rotation: 0,
                          scale: scale,
                          anchor: new google.maps.Point(0, 0),
                        };
                      // console.log(element);
                      var location = new google.maps.LatLng(element.centroid.location.lat, element.centroid.location.lon);
                      markersTemp.push({position: location, icon: svgMarkerPlace, type: 'place', zoom: zoomlevel});
                      heatmapData.push({location: location, weight: element.doc_count});
                  }
                );
              }
              this.heatmapData = heatmapData;
              this.markers = markersTemp;
              if (!this.autoCompleteInitCalled) {
                this.initAutoCompleteField();
              }
              this.apiService.setSearchInProgress(false);
          }
        },
        error => console.error(error),
        () => {
            console.log('observer got a complete notification (map)')

          }
      );



    // this.heatmapData = this.getHeatMapData();
    // this.setHeatMapData();


    // setTimeout(()=>this.apiLoaded.emit(this.Map));
        // this.Map.setMap(this.Map.gMap._googleMap);
    // this.apiService = ApiService;

  }


  // ngOnInit(): void {
  //   this.setHeatMapData();
  //
  //   console.log("And her2e");
  // }

  // setHeatMapData(): void {
  //   this.heatmapData = this.getHeatMapData();
  //   console.log(this.heatmapData);
  //   console.log("im here");
  // }

 //  boundsChanged(event: this.map.googleMap.boundsChanged): void {
 //   console.log(this.map.googleMap.getBounds());
 // }



 click(event: google.maps.MapMouseEvent) {
    // console.log(event)
  }

  updateMap(event=null, searchUpdated=false) {
     var bounds = this.map.getBounds().toJSON();
     var zoomlevel = this.map.getZoom();
     // var precission_heatmap = zoomlevel + 2;
     // if (precission_heatmap > 24) {
     //   precission_heatmap = 24;
     // }
     // var precission_grid = zoomlevel + 1;
     // if (precission_grid > 24) {
     //   precission_grid = 24;
     // }
    //  if(this.previous_zoom_level == zoomlevel && this.previous_zoom_level <= 6 && this.data_initialisation == false && searchUpdated == false) {

    //  }
    //  else
    //  {
    // }
    this.setMapData(zoomlevel, bounds.north, bounds.west, bounds.south, bounds.east)
     this.previous_zoom_level = zoomlevel;
     this.data_initialisation = false;

    //  console.log(zoomlevel);
     // console.log(precission_heatmap, precission_grid);
     // console.log(bounds)
     // if ( zoomlevel > 10 ) {
       // console.log(this.heatmapOptions)
       // this.heatmapOptions.dissipating = false;
       // this.heatmap.heatmap.setOptions( this.heatmapOptions );
       // this.heatmap.dissipating = false;
       // this.heatmap.options('dissipating', false);
       // this.heatmap.options({'dissipating':false, 'radius':100});
     // }
     // else
     // {
     //   this.heatmapOptions.dissipating = true;
     //   this.heatmap.heatmap.setOptions( this.heatmapOptions );
       // this.heatmap.options('dissipating', true);
       // this.heatmap.setOptions(radius= 50);
     // }
     // console.log(this.heatmapOptions.dissipating);


   }

   preventDefault(e=null): void {
     if(e != null) {
      e.preventDefault();
     }
   }


   markerClickEvent(center, zoom, marker_type, reference=null): void {
     if ( marker_type == "cluster" ) {
       var zoomlevel = this.map.getZoom();
       if (zoomlevel > zoom){
         this.zoom = zoomlevel + 1;
       }
       else
       {
         this.zoom = zoom + 1;
       }
       this.center = center;
     }
     else if ( marker_type == "place" ) {
       this.apiService.searchAtCoordinates(center);
       this.applicationState.setCurrentDisplay('search');
     }
   }

   setCenterToPositionOnNextZoomStep(center, zoom): void {
     this.zoom = zoom + 1;
     this.center = center;
   }

  setMapData(zoomlevel = 6, tl_lat = 66, tl_lon=-180, br_lat=-66, br_lon=180): void {
    // this.heatmapData = [
    //   {location: new google.maps.LatLng(50.3061856, 12.3007083), weight: 10},
    // ];
    // console.log(this.heatmapData);
    // console.log(JSON.stringify(this.googleMap));
    // console.log(JSON.stringify(this.map));
    this.apiService.setMapBounds(zoomlevel, tl_lat, tl_lon, br_lat, br_lon);
  }
}
